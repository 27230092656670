import "./FloatingCircle.css";

const FloatingCircle = ({ className, style }) => {
  let finalClassName = "floating-circle";
  if (className) {
    finalClassName += ` ${className}`;
  }
  return <div className={finalClassName} style={style} />;
};

export default FloatingCircle;
