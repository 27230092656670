import { useContext, useEffect, useRef } from "react";
import ContactUsPopup from "../components/ContactUsPopup";
import TopBar from "../components/TopBar";
import {
  PageContext,
  PageContextProvider,
} from "../context/PageContextProvider";
import Footer from "../Footer";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getAnalytics, logEvent } from "firebase/analytics";

const BasePage = ({
  pageTitle,
  pageDescription,
  showTopBar = true,
  children,
}) => {
  const props = {
    pageTitle,
    pageDescription,
    showTopBar,
    children,
  };
  return (
    <PageContextProvider>
      <BasePageContent {...props} />
    </PageContextProvider>
  );
};

const BasePageContent = ({
  pageTitle,
  pageDescription,
  showTopBar = true,
  children,
}) => {
  const pageEntered = useRef(false);
  const analytics = getAnalytics();
  const { showContactUsPopup } = useContext(PageContext);
  const { pathname, hash, key } = useLocation();
  const environment = process.env.REACT_APP_ENVIRONMENT;
  let finalTitle = environment === "dev" ? "[DEV] " : "";
  finalTitle += pageTitle ? `${pageTitle} | Ekorn` : "Ekorn";

  useEffect(() => {
    const scrollToSection = () => {
      if (hash && hash !== "") {
        setTimeout(() => {
          const id = hash.replace("#", "");
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }, 0);
      } else {
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 0);
      }
    };

    // Call the function when the component mounts
    scrollToSection();
  }, [pathname, hash, key]);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const pathWithoutPrefix = currentPath.substring(1);
    const pageName = pathWithoutPrefix.length > 0 ? pathWithoutPrefix : "home";

    // Case 1: Page entered
    if (!pageEntered.current) {
      logEvent(analytics, `page_${pageName}_entered`);
      pageEntered.current = true;
    }

    // Case 2: 10s and 20s on active page
    const timer2s = setTimeout(() => {
      if (pageEntered.current) {
        logEvent(analytics, `page_${pageName}_2s_active`);
      }
    }, 2000);

    const timer5s = setTimeout(() => {
      if (pageEntered.current) {
        logEvent(analytics, `page_${pageName}_5s_active`);
      }
    }, 5000);

    const timer10s = setTimeout(() => {
      if (pageEntered.current) {
        logEvent(analytics, `page_${pageName}_10s_active`);
      }
    }, 10000);

    const timer20s = setTimeout(() => {
      if (pageEntered.current) {
        logEvent(analytics, `page_${pageName}_20s_active`);
      }
    }, 20000);

    const beforeUnloadHandler = (event) => {
      logEvent(analytics, `page_${pageName}_exited`);
    };

    window.addEventListener("beforeunload", beforeUnloadHandler);

    return () => {
      // Case 3: Page exited
      logEvent(analytics, `page_${pageName}_exited_unmounted`);
      window.removeEventListener("beforeunload", beforeUnloadHandler);
      clearTimeout(timer2s);
      clearTimeout(timer5s);
      clearTimeout(timer10s);
      clearTimeout(timer20s);
    };
  }, [analytics]);

  return (
    <>
      <header className="base-page-header">{showTopBar && <TopBar />}</header>
      <div className="base-page">
        <Helmet>
          <title>{finalTitle}</title>
          <meta name="description" content={pageDescription} />
        </Helmet>
        {children}
        {showContactUsPopup && <ContactUsPopup />}
      </div>
      <Footer />
    </>
  );
};

export default BasePage;
