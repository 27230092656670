import { useContext, useState } from "react";
import Popup from "./Popup";
import "./AddCommandPopup.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { EkornAppContext } from "../context/EkornAppContextProvider";

const styles = {
  tooltip: { fontSize: "12px" },
};

export const AddCommandPopup = ({ closePopup, commandToEdit }) => {
  console.log(commandToEdit);
  const { commands, isCorporateUser, baseAPIURL } = useContext(EkornAppContext);
  // eslint-disable-next-line
  const [commandID, _setCommandID] = useState(commandToEdit?.id ?? "");
  const [command, setCommand] = useState(commandToEdit?.command ?? "");
  const [name, setName] = useState(commandToEdit?.name ?? "");
  const [description, setDescription] = useState(
    commandToEdit?.description ?? ""
  );
  const [actionUrl, setActionUrl] = useState(commandToEdit?.emptyAction ?? "");
  const [actionUrlWithArguments, setActionUrlWithArguments] = useState(
    commandToEdit?.actionWithArguments ?? ""
  );
  const [type, setType] = useState(commandToEdit?.type ?? "PERSONAL");
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const isExistingCommand = (commandName) => commandName in commands;

  const isEditingCommand = commandToEdit ? true : false;

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage();
    if (!isEditingCommand && isExistingCommand(command)) {
      setErrorMessage("Shortcut already exists, please select another one.");
      return;
    }

    setIsLoading(true);
    const endpoint = isEditingCommand
      ? `${baseAPIURL}/edit`
      : `${baseAPIURL}/ekorn`;
    axios
      .post(
        endpoint,
        {
          id: commandID,
          command,
          name,
          description,
          emptyAction: actionUrl,
          actionWithArguments: actionUrlWithArguments,
          type: type.toUpperCase(),
        },
        {
          withCredentials: true,
        }
      )
      .then((_response) => {
        refreshPage();
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setIsLoading(false);
      });
  };

  function refreshPage() {
    window.location.reload();
  }

  const commandChangeHandler = (e) => {
    setCommand(e.target.value.toLowerCase());
    e.target.setCustomValidity("");
  };
  const actionURLChangeHandler = (e) => {
    setActionUrl(e.target.value);
    e.target.setCustomValidity("");
  };
  const actionWithArgumentsURLChangeHandler = (e) => {
    setActionUrlWithArguments(e.target.value);
    e.target.setCustomValidity("");
  };

  return (
    <Popup closePopup={closePopup}>
      {errorMessage && <p>{errorMessage}</p>}

      <div className="add-popup">
        <h4 style={{ margin: "0" }}>Add a new shortcut</h4>
        <form onSubmit={handleSubmit}>
          <div className="popup-input-container">
            <p>
              Shortcut
              <FontAwesomeIcon
                data-tooltip-id="command"
                icon={faCircleInfo}
                className="popup-tooltip"
              />
            </p>
            <input
              className="custom-input"
              type="text"
              value={command}
              onChange={commandChangeHandler}
              pattern="[a-z0-9]+"
              disabled={isEditingCommand}
              onInvalid={(e) => {
                e.target.setCustomValidity(
                  "Commands should be lower case and with no spaces"
                );
              }}
              required
            />
            <ReactTooltip
              id="command"
              content="The command should be lowercase and with no spaces"
              style={styles.tooltip}
            />
          </div>

          <div className="popup-input-container">
            <p>
              Name
              <FontAwesomeIcon
                data-tooltip-id="name"
                icon={faCircleInfo}
                className="popup-tooltip"
              />
            </p>
            <input
              className="custom-input"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <ReactTooltip
              id="name"
              content="Pick any name for your command."
              style={styles.tooltip}
            />
          </div>

          <div className="popup-input-container">
            <p>
              Description
              <FontAwesomeIcon
                data-tooltip-id="description"
                icon={faCircleInfo}
                className="popup-tooltip"
              />
            </p>
            <input
              className="custom-input"
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
            <ReactTooltip id="description" style={styles.tooltip}>
              Thoroughly describe what your shortcut does.
              <br />
              If you have a dynamic URL, describe what the parameter is.
            </ReactTooltip>
          </div>

          <div className="popup-input-container">
            <p>
              Static URL
              <FontAwesomeIcon
                data-tooltip-id="actionURL"
                icon={faCircleInfo}
                className="popup-tooltip"
              />
            </p>
            <input
              className="custom-input"
              type="url"
              value={actionUrl}
              onChange={actionURLChangeHandler}
              onInvalid={(e) => {
                e.target.setCustomValidity(
                  "Make sure you're including https:// or http:// in your URL"
                );
              }}
              required
            />
            <ReactTooltip id="actionURL" style={styles.tooltip}>
              This is usually the main website of the tool and e.g.
              https://github.com/my-org/my-repo
              <br />
              Make sure to include https:// or http://
            </ReactTooltip>
          </div>

          <div className="popup-input-container">
            <p>
              Dynamic URL
              <FontAwesomeIcon
                data-tooltip-id="urlWithArguments"
                icon={faCircleInfo}
                className="popup-tooltip"
              />
            </p>
            <input
              className="custom-input"
              type="url"
              value={actionUrlWithArguments}
              onChange={actionWithArgumentsURLChangeHandler}
              onInvalid={(e) => {
                e.target.setCustomValidity(
                  "Make sure you're including https:// or http:// in your URL"
                );
              }}
            />
            <ReactTooltip id="urlWithArguments" style={styles.tooltip}>
              This is optional. Add a URL that includes %s where you want to add
              the query,
              <br />
              e.g. https://www.google.com/search?q=%s will replace any query
              where %s is located
            </ReactTooltip>
          </div>

          <div className="popup-input-container">
            <p>
              Type
              <FontAwesomeIcon
                data-tooltip-id="type"
                icon={faCircleInfo}
                className="popup-tooltip"
              />
            </p>
            <select
              value={type}
              onChange={(e) => setType(e.target.value)}
              disabled={isEditingCommand || !isCorporateUser}
            >
              <option value="PERSONAL">Personal</option>
              <option value="CORPORATE">Company</option>
            </select>
            <ReactTooltip
              id="type"
              content="You can make commands just for yourself or everyone at the company. Default is Personal."
              style={styles.tooltip}
            />
          </div>
          <div>
            <button
              className="commands-button"
              type="submit"
              style={{ alignSelf: "center" }}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </Popup>
  );
};
