import { useContext, useEffect, useRef } from "react";
import { EkornAppContext } from "../context/EkornAppContextProvider";
import ekornSetupVideo from "./ekorn-setup.mp4";
import "./InstallPage.css";
import BasePage from "./BasePage";

export default function InstallPage() {
  const { isSmallScreen, isLargeScreen } = useContext(EkornAppContext);
  const instructionsContainerClassName = isSmallScreen
    ? "instructions-container-small"
    : "instructions-container";
  const scrollToSteps = useRef(null);
  const videoWidth = isSmallScreen ? "80%" : isLargeScreen ? "40%" : "60%";

  const handleManualStepsClick = (e) => {
    e.preventDefault();
    scrollToSteps.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <BasePage
      pageTitle="Install Chrome Extension"
      pageDescription="Ekorn is easy to install. Similar to bunnylol, Golinks or Yolinks, Ekorn is a search engine that needs to be setup in your browser."
    >
      <h2>Install Chrome Extension</h2>
      <p style={{ fontSize: "18px" }}>
        If you're using Chrome, just{" "}
        <a
          href="https://chrome.google.com/webstore/detail/ekorn-search/kdfjbjabfbfcgenckohbfjoobmaijdmh?hl=en"
          target="__blank"
          rel="noopener noreferrer"
        >
          install the Chrome Extension
        </a>{" "}
        or follow our{" "}
        <a href="#" onClick={handleManualStepsClick}>
          manual instructions
        </a>
        .<br />
        Similar to bunnylol, Ekorn is a search engine that runs in your browser,
        so it needs to be set up.
      </p>

      <h2>Other Browsers</h2>
      <p style={{ fontSize: "18px" }}>
        Unfortunatelly we only support Chrome and any browser that allows you to
        set up a custom search engine.
        <br />
        Drop me a line if there's a browser you want us to support:{" "}
        <a
          href="mailto:antonio@ekorn.xyz"
          target="__blank"
          rel="noopener noreferrer"
        >
          antonio@ekorn.xyz
        </a>
      </p>
      <h2 ref={scrollToSteps}>Manual Installation</h2>
      <p style={{ fontSize: "18px" }}>
        If you want to do the installation yourself, please follow the next
        steps/video.
      </p>
      <div style={{ width: videoWidth }}>
        <video
          src={ekornSetupVideo}
          autoPlay={!isSmallScreen}
          loop
          muted
          controls
          className="video-player"
        />
      </div>
      <div className={instructionsContainerClassName}>
        <h2>Instructions</h2>
        <ol className="instructions-list">
          <li>
            Setup Ekorn as your default engine (don't worry, you'll keep Google!
            Any non-existent command will be Googled).
          </li>
          <li>
            Add any name and shortcut you want but make sure the URL is{" "}
            <code>https://api.ekorn.xyz/?q=%s</code>
          </li>
          <li>Make Ekorn the default search engine.</li>
          <li>
            Change Google to have shortcut as <code>g</code>.
          </li>
          <li>
            Voilá. You can now search with Ekorn, if you need Google
            suggestions, just type <code>g</code> followed by a space.
          </li>
        </ol>
      </div>
    </BasePage>
  );
}
