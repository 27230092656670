import { Helmet } from "react-helmet";

const FallbackMetaTags = () => {
  return (
    <Helmet>
      <title>Ekorn | Smart shortcuts and AI-powered search</title>
      <meta
        name="description"
        content="Unleash the power of smart shortcuts and GenAI to find and share information. Inspired by bunnylol at Facebook/Meta, Golinks at Google and Yolinks at Yahoo."
      />
    </Helmet>
  );
};

export default FallbackMetaTags;
