import { useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import {
  getPersonalAPIEndpoint,
  getEnterpriseAPIEndpoint,
} from "../common/Environment";
import Commands from "../components/Commands";
import { AddCommandPopup } from "../components/AddCommandPopup";
import BounceLoader from "react-spinners/BounceLoader";
import { EkornAppContext } from "../context/EkornAppContextProvider";
import "./CommandsPage.css";

export default function CommandsPage() {
  // eslint-disable-next-line
  const [searchParams, _setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const { setCommands, user, setUser, baseAPIURL } =
    useContext(EkornAppContext);
  const [showAddCommandPopup, setShowAddCommandPopup] = useState(false);
  const enterpriseID = searchParams.get("enterprise-id");

  // We don't get this one from context given that at this point we don't
  // know who's even the user
  const apiUrl = enterpriseID
    ? getEnterpriseAPIEndpoint(enterpriseID)
    : getPersonalAPIEndpoint();

  useEffect(() => {
    axios
      .get(`${apiUrl}/commands`, {
        withCredentials: true,
      })
      .then((response) => {
        setUser(response.data.user);
        setCommands(response.data.commands);
        setIsLoading(false);
      })
      .catch((error) => {
        // TODO: Deal with error
        setIsLoading(false);
      });
  }, []);

  const closeAddCommandPopup = () => setShowAddCommandPopup(false);

  return (
    <header className="base-page">
      <TopView
        isLoading={isLoading}
        user={user}
        enterpriseID={enterpriseID}
        baseAPIURL={baseAPIURL}
        setShowAddCommandPopup={setShowAddCommandPopup}
      />
      <div>
        <Commands />
        <BounceLoader
          color="#51b7d3"
          loading={isLoading}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </div>

      {user && (
        <form action={`${baseAPIURL}/log-out`} method="post">
          <div style={{ display: "flex" }}>
            <button className="commands-button" type="submit">
              Log out
            </button>
          </div>
        </form>
      )}
      {showAddCommandPopup && (
        <AddCommandPopup closePopup={closeAddCommandPopup} />
      )}
    </header>
  );
}

function TopView({
  isLoading,
  user,
  enterpriseID,
  baseAPIURL,
  setShowAddCommandPopup,
}) {
  if (isLoading) {
    return null;
  }
  const header = !user
    ? "Login to add custom commands."
    : enterpriseID
    ? enterpriseID
    : `Welcome, ${user.username}!`;
  return (
    <div className="commands-topbar">
      {user ? (
        <>
          <h2 className="topbar-header">{header}</h2>
          <button
            className="add-command"
            onClick={() => setShowAddCommandPopup(true)}
          >
            Add Shortcut
          </button>
        </>
      ) : (
        <form
          className="login-form"
          action={`${baseAPIURL}/login`}
          method="get"
        >
          <div className="login-form-container">
            <h2 className="topbar-header">{header}</h2>
            <button className="add-command" type="submit">
              Login
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
