import React, { createContext, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDFZpOjMB6Bai5U_UyuXEanCMNfjDB0xtc",
  authDomain: "koala-search.firebaseapp.com",
  projectId: "koala-search",
  storageBucket: "koala-search.appspot.com",
  messagingSenderId: "438300717164",
  appId: "1:438300717164:web:24e3e4e00b71b18904e849",
  measurementId: "G-VV4VV2R9TL",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const FirebaseContext = createContext();

function FirebaseContextProvider({ children }) {
  const [firebaseAnalytics, setFirebaseAnalytics] = useState(analytics);

  useEffect(() => {
    setFirebaseAnalytics(analytics);
  }, []);

  return (
    <FirebaseContext.Provider value={firebaseAnalytics}>
      {children}
    </FirebaseContext.Provider>
  );
}

export { FirebaseContext, FirebaseContextProvider };
