import React from "react";
import { useState } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useLocation } from "react-router-dom";
import "./LoginPage.css";
import axios from "axios";
import { getEnterpriseAPIEndpoint } from "../common/Environment";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function LoginPage() {
  let query = useQuery();
  const id = query.get("id");
  const domain = query.get("domain");

  const [showLinkVerification, setShowLinkVerification] = useState(false);

  const onCodeSent = (emailPrefix) => {
    setShowLinkVerification(true);
  };
  return showLinkVerification ? (
    <LinkConfirmation />
  ) : (
    <LoginCredentials id={id} domain={domain} onCodeSent={onCodeSent} />
  );
}

function LinkConfirmation(props) {
  return (
    <header className="base-page">
      <h1>Check your email 💌</h1>
      <p>
        We've sent you an email. Follow the instructions and continue there!
      </p>
      <p>You can close this window.</p>
    </header>
  );
}

function LoginCredentials(props) {
  const id = props.id;
  const domain = props.domain;
  const apiUrl = getEnterpriseAPIEndpoint(id);
  const analytics = getAnalytics();

  const [emailPrefix, setEmailPrefix] = useState("");
  const [emailStartedTyping, setEmailStartedTyping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const handleEmailChange = (event) => {
    if (!emailStartedTyping) {
      logEvent(analytics, "enterprise_login_email_start_typing");
      setEmailStartedTyping(true);
    }
    setEmailPrefix(event.target.value);
  };

  const submitLoginCredentials = (event) => {
    event.preventDefault();

    logEvent(analytics, "enterprise_logging_in");

    setErrorMessage(undefined);
    setIsLoading(true);

    axios
      .post(`${apiUrl}/log-in`, {
        enterprise_id: id,
        username: emailPrefix,
      })
      .then((_response) => {
        logEvent(analytics, "enterprise_login_request_sent");
        setIsLoading(false);
        props.onCodeSent(emailPrefix);
      })
      .catch((error) => {
        var errorMessage = "Unexpected Error.";
        if (error.response && error.response.data.error) {
          errorMessage = error.response.data.error;
        }
        logEvent(analytics, "error_enterprise_login_request", {
          enterprise_id: id,
          email: emailPrefix + `@${domain}`,
          error_message: JSON.stringify(error),
        });
        setErrorMessage(errorMessage);
        setIsLoading(false);
      });
  };

  return (
    <header className="base-page">
      <h1>Login in Ekorn 🐿️ using your {id} email</h1>
      <p>An email will be sent to your email with a verification code.</p>
      <form onSubmit={submitLoginCredentials}>
        <input
          className="text-input"
          type="text"
          placeholder="Your corporate email"
          value={emailPrefix}
          onChange={handleEmailChange}
          required
        />
        @{domain}
        <button type="submit" className="popup-button" disabled={isLoading}>
          {isLoading ? "Loading..." : "Login"}
        </button>
      </form>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </header>
  );
}
