import React, { useEffect, useRef } from "react";
import "./Popup.css";

const Popup = ({ className, closePopup, children }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [closePopup]);

  return (
    <div className="popup-overlay">
      <div className={`popup ${className ?? ""}`} ref={popupRef}>
        {children}
      </div>
    </div>
  );
};

export default Popup;
