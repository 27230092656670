export function getPersonalAPIEndpoint() {
  return process.env.REACT_APP_API_URL;
}

export function getEnterpriseAPIEndpoint(enterpriseID) {
  return process.env.REACT_APP_ENTERPRISE_API_URL.replace("%s", enterpriseID);
}

export function getAPIEndpoint(user) {
  const isCorporateUser = user && user.enterpriseID;
  return isCorporateUser
    ? getEnterpriseAPIEndpoint(user.enterpriseID)
    : getPersonalAPIEndpoint();
}
