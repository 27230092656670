import React, { createContext, useState } from "react";

const PageContext = createContext();

const PageContextProvider = ({ children }) => {
  const [showContactUsPopup, setShowContactUsPopup] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const openContactUsPopup = () => {
    setShowContactUsPopup(true);
  };

  const closeContactUsPopup = () => {
    setShowContactUsPopup(false);
  };

  const openSidebar = () => {
    setShowSidebar(true);
  };

  const closeSidebar = () => {
    setShowSidebar(false);
  };

  return (
    <PageContext.Provider
      value={{
        showContactUsPopup,
        openContactUsPopup,
        closeContactUsPopup,
        showSidebar,
        openSidebar,
        closeSidebar,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export { PageContext, PageContextProvider };
