import "./ToggleChip.css";

const ToggleChip = ({ active, onClick, ...props }) => {
  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  };
  return (
    <div
      className={`toggle-chip ${active ? "active" : ""}`}
      onClick={handleClick}
      {...props}
    >
      {props.children}
    </div>
  );
};

export default ToggleChip;
