import React, { useContext, useEffect, useRef } from "react";
import "./TopBar.css";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useNavigate } from "react-router-dom";
import { EkornAppContext } from "../context/EkornAppContextProvider";
import { PageContext } from "../context/PageContextProvider";
import FloatingCircle from "./FloatingCircle";
import CTAButton, { ButtonType } from "./CTAButton";
import { FAQS_PATH, PRICING_PATH, PRODUCT_PATH } from "../utils/ProductUtils";

const MENU_ITEMS = [
  {
    title: "Product",
    url: PRODUCT_PATH,
  },
  {
    title: "Pricing",
    url: PRICING_PATH,
  },
  {
    title: "FAQs",
    url: FAQS_PATH,
  },
];

export default function TopBar() {
  const navigate = useNavigate();
  const topbarRef = useRef(null);

  return (
    <>
      <div ref={topbarRef} className="topbar">
        <div className="brand-name" onClick={() => navigate("/")}>
          <img
            src="EkornLogo.svg"
            className="brand-logo"
            alt="Ekorn brand logo"
          />
          Ekorn
        </div>
        <NavbarMenu />
      </div>
    </>
  );
}

function NavbarMenu() {
  const { showSidebar, openSidebar, openContactUsPopup } =
    useContext(PageContext);
  const analytics = getAnalytics();
  const { isSmallScreen } = useContext(EkornAppContext);
  const navigate = useNavigate();
  if (isSmallScreen) {
    return (
      <div className="right-section">
        <FloatingCircle className="menu-circle" />
        {showSidebar && <Sidebar />}
        <i
          className="fas fa-bars menu-cta-button"
          onClick={() => {
            logEvent(analytics, "show_sidebar_button_clicked");
            openSidebar();
          }}
        />
      </div>
    );
  }

  return (
    <div className="right-section">
      {MENU_ITEMS.map((menuItem, index) => (
        <MenuButton
          key={index}
          onClick={(e) => {
            e.preventDefault();
            logEvent(analytics, `topbar_${menuItem.title}_clicked`);
            navigate(menuItem.url);
          }}
        >
          {menuItem.title}
        </MenuButton>
      ))}
      <CTAButton
        type={ButtonType.MENU}
        style={{ marginLeft: "22px" }}
        onClick={() => {
          logEvent(analytics, `topbar_contact_us_clicked`);
          openContactUsPopup();
        }}
      >
        Contact Us
      </CTAButton>
    </div>
  );
}

function MenuButton({ children, onClick }) {
  return (
    <button className="menu-button" onClick={onClick}>
      {children}
    </button>
  );
}

function Sidebar() {
  const { closeSidebar } = useContext(PageContext);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closeSidebar();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [closeSidebar]);

  return (
    <div className="sidebar-overlay">
      <div className="sidebar" ref={popupRef}>
        <Buttons />
      </div>
    </div>
  );
}

function Buttons() {
  const { closeSidebar } = useContext(PageContext);
  const navigate = useNavigate();
  const analytics = getAnalytics();
  return (
    <>
      {MENU_ITEMS.map((menuItem, index) => (
        <button
          key={index}
          className="page-button"
          onClick={() => {
            logEvent(analytics, `sidenav_${menuItem.title}_clicked`);
            navigate(menuItem.url);
            closeSidebar();
          }}
        >
          {menuItem.title}
        </button>
      ))}
    </>
  );
}
