import { useContext, useEffect, useRef, useState } from "react";
import { darkenColor } from "../../utils/ColorUtils";
import FeatureList from "./FeatureList";
import "./PricingBox.css";
import "./PricingBoxV2.css";
import {
  JEY_CALENDLY_INVITE,
  CHROME_EXTENSION_URL,
} from "../../utils/ProductUtils";
import { getAnalytics, logEvent } from "firebase/analytics";
import { setUpEntryAnimation } from "../../utils/AnimationUtils";
import { EkornAppContext } from "../../context/EkornAppContextProvider";
import TrackableComponent from "../../common/TrackableComponent";

const ctaButtonStyles = {
  backgroundColor: "transparent",
  cursor: "pointer",
  transition: "background-color 0.3s, color 0.3s",
  borderRadius: "8px",
  fontSize: "18px",
  margin: "30px 30px",
  padding: "4px 0px",
  fontFamily: "Sen",
};

const PriceType = {
  FREE: "FREE",
  STARTER: "STARTER",
  PRO: "PRO",
};

const PRICES_METADATA = [
  {
    title: "Individual",
    description: "For casual users working individually",
    color: "grey",
    price: "Free",
    type: PriceType.FREE,
    cta: "Try it",
    ctaURL: CHROME_EXTENSION_URL,
    features: [
      { name: "Ekorn hub" },
      { name: "Preset shortcuts" },
      { name: "Unlimited Personal shortcuts" },
      { name: "Company shortcuts", isExcluded: true },
      { name: "Dedicated support", isExcluded: true },
      { name: "AI Shortcuts", isExcluded: true },
      { name: "SSO (e.g. Okta)", isExcluded: true },
      { name: "Onboarding & training", isExcluded: true },
    ],
  },
  {
    title: "Starter",
    description: "For small and medium size teams",
    color: darkenColor("#77F7C3", 15),
    price: "2",
    type: PriceType.STARTER,
    cta: "Get a demo",
    ctaURL: JEY_CALENDLY_INVITE,
    isMonthly: true,
    smallPrint: "*40 users minimum required for this subscription",
    features: [
      { name: "Ekorn hub" },
      { name: "Preset shortcuts" },
      { name: "Unlimited Personal shortcuts" },
      { name: "Unlimited Company shortcuts" },
      { name: "Up to 250 members" },
      { name: "Dedicated customer support" },
      { name: "AI Shortcuts", isExcluded: true },
      { name: "SSO (e.g. Okta)", isExcluded: true },
      { name: "Onboarding & training", isExcluded: true },
    ],
  },
  {
    title: "Pro",
    description: "For productive teams who'd like to leverage AI",
    color: "#A18EED",
    price: "Custom",
    type: PriceType.PRO,
    cta: "Get a demo",
    ctaURL: JEY_CALENDLY_INVITE,
    priceSubtitle: "pricing",
    features: [
      { name: "Unlimited AI Shortcuts", isHighlighted: true },
      { name: "Ekorn hub" },
      { name: "Preset shortcuts" },
      { name: "Unlimited Personal shortcuts" },
      { name: "Unlimited Company shortcuts" },
      { name: "Unlimited members" },
      { name: "Dedicated customer support" },
      { name: "SSO (e.g. Okta)" },
      { name: "Onboarding & training" },
    ],
  },
];

const PricingBoxV2 = () => {
  const { isSmallScreen } = useContext(EkornAppContext);
  const sectionRef = useRef(null);

  useEffect(() => {
    setUpEntryAnimation(sectionRef, isSmallScreen ? 0.05 : undefined);
  }, [sectionRef, isSmallScreen]);
  return (
    <TrackableComponent label={"PR | Pricing Boxes"}>
      <div className="pricing-v2-container animated-section" ref={sectionRef}>
        {PRICES_METADATA.map((priceMetadata) => (
          <PriceContainer
            key={priceMetadata.title}
            priceMetadata={priceMetadata}
          />
        ))}
      </div>
    </TrackableComponent>
  );
};

const PriceContainer = ({ priceMetadata }) => {
  return (
    <div className="pricing-v2-card-container">
      <div className="pricing-v2-card">
        <Arrow text={priceMetadata.title} color={priceMetadata.color} />
        <div className="pricing-v2-content">
          <p
            style={{
              color: priceMetadata.color,
            }}
            className="pricing-v2-content-description"
          >
            {priceMetadata.description}
          </p>
          <Price priceMetadata={priceMetadata} />
          <FeatureList features={priceMetadata.features} />
        </div>
        <PriceCTAButton priceMetadata={priceMetadata} />
      </div>
      {priceMetadata.smallPrint && (
        <p style={{ margin: "4px", fontSize: "11px" }}>
          {priceMetadata.smallPrint}
        </p>
      )}
    </div>
  );
};

const PriceCTAButton = ({ priceMetadata }) => {
  const analytics = getAnalytics();
  const [isHovered, setIsHovered] = useState(false);
  const color = priceMetadata.color;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const finalButtonStyles = {
    ...ctaButtonStyles,
    border: `2px solid ${color}`,
    backgroundColor: color,
    color: "white",
  };
  const ctaHoverStyles = {
    color: color,
    border: `2px solid ${color}`,
    backgroundColor: "transparent",
  };

  return (
    <button
      style={
        isHovered
          ? { ...finalButtonStyles, ...ctaHoverStyles }
          : finalButtonStyles
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        logEvent(analytics, `cta_${priceMetadata.type}_clicked`);
        window.open(priceMetadata.ctaURL, "_blank");
      }}
    >
      {priceMetadata.cta}
    </button>
  );
};

const Price = ({ priceMetadata }) => {
  if (priceMetadata.type !== PriceType.STARTER) {
    return (
      <div className="pricing-v2-price-box">
        <div className="new-pricing-price-box">
          <div
            className="new-pricing-value"
            style={{ flexDirection: "column" }}
          >
            <h1 className="pricing-v2-text">{priceMetadata.price}</h1>
            {priceMetadata.priceSubtitle && (
              <p className="pricing-v2-price-subtitle">
                {priceMetadata.priceSubtitle}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="pricing-v2-price-box">
      <div className="new-pricing-value">
        <h4 className="new-pricing-sign">$</h4>
        <h1 className="new-pricing-digit">{priceMetadata.price}</h1>
        <h4 className="new-pricing-asterisk">.99</h4>
      </div>
      <div className="pricing-v2-frequency-container">
        <p className="pricing-v2-frequency">per user*</p>
        <p className="pricing-v2-frequency">/ month</p>
      </div>
    </div>
  );
};

const Arrow = ({ text, color }) => {
  return (
    <div className="arrow-container">
      <div className="arrow-rectangle" style={{ backgroundColor: color }}>
        <h2>{text}</h2>
      </div>
      <div
        className="arrow-triangle"
        style={{ borderLeft: `25px solid ${color}` }}
      />
    </div>
  );
};

export default PricingBoxV2;
