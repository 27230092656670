import { useContext, useEffect, useRef, useState } from "react";
import "./FAQsList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { setUpEntryAnimation } from "../../utils/AnimationUtils";
import { EkornAppContext } from "../../context/EkornAppContextProvider";
import { CHROME_EXTENSION_URL } from "../../utils/ProductUtils";
import TrackableComponent from "../../common/TrackableComponent";

const FAQList = [
  {
    question: "How do I pronounce Ekorn?",
    answer: "Eeh-korn",
  },
  {
    question: "What's Ekorn?",
    answer: (
      <>
        Ekorn is a tool that lets you type shortcuts in your browser's address
        bar to directly navigate to the relevant information that you need (e.g{" "}
        <span className="ekorn-gradient">product roadmap</span> or{" "}
        <span className="ekorn-gradient">Jira ticket 123</span>). It works by
        redirecting your browser to the tool or URL associated with the
        shortcut. We will also include the parameters you send with it (e.g.{" "}
        <span className="ekorn-gradient">maps 1414 E Yesler Way</span>)
      </>
    ),
  },
  {
    question: "What are Ekorn shortcuts?",
    answer: (
      <>
        Shortcuts are words or letters that you can type to quickly go to any
        tool or URL you want. For example, you can type{" "}
        <span className="ekorn-gradient">git</span> to go to your github
        repository, or <span className="ekorn-gradient">ticket</span> to go to
        the list of tickets on jira. You can also create your own shortcuts for
        any tool or URL you use frequently.
        <br />
        <br />
        Lastly, you can add additional search terms to speed up your searches or
        quickly access information. For example, you can type{" "}
        <span className="ekorn-gradient">wiki onboarding doc</span> to quickly
        locate the onboarding doc within your wiki, or type{" "}
        <span className="ekorn-gradient">tickets TICKET123</span> to promptly
        open the corresponding ticket page.
      </>
    ),
  },
  {
    question: "What are personal Ekorn shortcuts?",
    answer: (
      <>
        Personal shortcuts are private, visible, and usable only by the creator
        (e.g., I set up <span className="ekorn-gradient">spswift</span> to go
        directly to Taylor Swift's Spotify page, and only I can use it). We
        recommend using personal shortcuts for specific or private needs. By
        default, new Ekorn shortcuts are saved as 'personal'.
      </>
    ),
  },
  {
    question: "What are company Ekorn shortcuts?",
    answer:
      "Company shortcuts are available to everyone within your organization and can be created by anyone. We recommend you categorize the majority of your shortcuts as 'company' in order to streamline sharing with your team or organization.",
  },
  {
    question: "Who can create a company shortcut?",
    answer:
      "Anyone at the company can create a shortcut. The power of Ekorn lies in making information accessible to everyone at the company. However, please let us know if you require tighter control, and we'll be happy to assist you.",
  },
  {
    question: "How does Ekorn work?",
    answer:
      "In reality, Ekorn is a search engine that maps your input into shortcuts to redirect you to the correct URL, including any additional terms you add. Any unrecognized shortcut is automatically redirected to Google, so it won't interfere with your current workflows. Additionally, our AI-powered shortcut system allows you to forget about both shortcuts and URLs entirely by letting you ask for what you need.",
  },
  {
    question: "Is Ekorn free?",
    answer: (
      <>
        Absolutely, for personal use, Ekorn is completely free!
        <br />
        <br />
        However, if you want to share Ekorn with your team/company or unlock
        advanced features, you'll need to upgrade to our Starter or Pro plan.
      </>
    ),
  },
  {
    question: "Do you have a free trial?",
    answer: (
      <>
        While we don't offer free trials, we provide a 60-day
        money-back-guarantee period for your company to explore and try Ekorn.
        This approach allows us to provide a tailored and customized onboarding
        and setup experience for every customer.
        <br />
        <br />
        That said, we do occasionally offer limited free trials in exchange for
        valuable product feedback on new features we're rolling out. If you're
        interested in learning more about this initiative, please don't hesitate
        to reach out to us.
      </>
    ),
  },
  {
    question: "What's your privacy policy?",
    answer: (
      <>
        At Ekorn, your privacy is our top priority. You can rest assured that we
        neither sell nor share your information with third parties. For more
        details, please review our comprehensive privacy policy on our{" "}
        <a href="/privacy" target="_blank" rel="noopener noreferrer">
          Privacy Page
        </a>
        .
      </>
    ),
  },
  {
    question: "Is Ekorn secure?",
    answer: (
      <>
        Ekorn is designed with security in mind. You can have complete
        confidence that your information is kept isolated and entirely private.
        <br />
        <br />
        <span className="bold">Here's how it works:</span> Ekorn primarily
        operates as a search engine, redirecting you to predefined URLs through
        shortcuts. We only store information related to your links/shortcuts for
        product purposes but we do not access or store the information that your
        shortcuts are related to, nor do we store your sensitive company
        information, insider data, or any confidential content. Think of us as
        similar to using Google search - our focus is on improving your web
        navigation, not on collecting or sharing your data. Your privacy and
        security are paramount to us, and we take every measure to ensure a safe
        and secure browsing experience. We will only access the information
        necessary to provide you with support and enhance your user experience
        to the fullest.
      </>
    ),
  },
  {
    question:
      "How do I get Ekorn Starter/Ekorn Pro? What is the installation process?",
    answer: (
      <>
        Our installation process is designed to be seamless and flexible,
        tailored to your specific requirements:
        <br />
        <br />
        <span style={{ fontWeight: 900 }}>
          For Companies with Managed Devices:
        </span>{" "}
        We collaborate with your IT team to ensure a smooth installation
        process. They will help deploy Ekorn to everyone's Chrome browser,
        ensuring widespread access.
        <br />
        <br />
        <div style={{ marginLeft: "16px" }}>
          For Ekorn Starter Plan Users: After the update is rolled out to all
          devices, individual users can easily log in using a convenient Magic
          Link that will be emailed to them.
        </div>
        <br />
        <div style={{ marginLeft: "16px" }}>
          For Ekorn Pro Plan Users: After the update is rolled out to all
          devices, individual users can easily log in using Single Sign-On (SSO)
          for a streamlined login experience.
        </div>
        <br />
        <br />
        Please note that at this time, our primary focus is currently on
        providing the best experience for companies with managed devices. While
        we aim to accommodate various scenarios, our support for companies
        without managed devices may be limited at this time. Ensuring a seamless
        and secure experience for managed environments is our top priority.
        <br />
        <br />
        We remain open to considering support for companies without managed
        devices on a case-by-case basis in the future. Your feedback and
        requirements are vital to our growth, and we appreciate your
        understanding as we explore expanding our support. Please reach out with
        any questions or specific needs, and we'll do our best to assist within
        our current capabilities.
      </>
    ),
  },
  {
    question: "Will you help with onboarding and administration of the site?",
    answer: (
      <>
        Absolutely, we're committed to ensuring a smooth onboarding process and
        ongoing administration for your company. Here's how we assist:
        <br />
        <br />
        <ol>
          <li>
            Collaboration with Your IT Team: We'll collaborate closely with your
            IT department to ensure that every team member has seamless access
            to Ekorn. This includes deploying Ekorn to all relevant devices and
            addressing any technical issues that may arise.
          </li>
          <br />
          <li>
            Tool Integration: During onboarding, we'll take the time to
            understand the specific tools and software your company uses. With
            this knowledge, we'll set up company shortcuts tailored to your
            needs. This means you won't have to worry about configuring them
            yourself, saving you time and effort.
          </li>
          <br />
          <li>
            Training and Instructions: We're dedicated to ensuring that your
            team gets the most out of Ekorn. As part of our service, we offer
            comprehensive training and clear instructions to empower your
            employees to navigate the platform effectively. This helps maximize
            productivity from day one.
          </li>
          <br />
          <li>
            Ongoing Support: Our commitment to your success doesn't end with
            onboarding. You can always reach out to us for any help or ongoing
            support you may need. Whether it's troubleshooting, optimizing your
            setup, or answering any questions that arise, our team is always
            here to assist you.
          </li>
          <br />
        </ol>
        At Ekorn, we understand the importance of a seamless onboarding process
        and ongoing support for your company. Our aim is to make your experience
        as efficient and user-friendly as possible, so you can focus on what
        matters most - achieving your goals.
        <br />
        <br />
      </>
    ),
  },
  {
    question: "Is Ekorn available for your phone?",
    answer:
      "Currently, Ekorn is not available for mobile devices. However, we are actively exploring mobile options for the future, and it's something we'll consider based on the demand from our users. Your feedback and interest are vital in shaping the direction of our development. Stay tuned for updates!",
  },
  {
    question: "How do I get started? How do I get one of the plans?",
    answer: (
      <>
        Getting started with Ekorn is easy, and it depends on your needs:
        <br />
        <br />
        For Personal Use: If you're looking to use Ekorn for personal purposes,
        it's entirely free! Just head over to our{" "}
        <a
          href={CHROME_EXTENSION_URL}
          target="__blank"
          rel="noopener noreferrer"
        >
          Chrome extension page
        </a>
        , download the extension, and you're ready to go.
        <br />
        <br />
        For Starter or Pro Plans: If you're interested in our Starter or Pro
        plans, the first step is to get in touch with us! We're here to guide
        you through the process. We'll begin with a personalized demo to
        showcase Ekorn's capabilities and discuss how it can best benefit your
        team or organization.
      </>
    ),
  },
  {
    question: "How do I pay for one of the plans?",
    answer: (
      <>
        After you've gone through the demo and decided to subscribe to one of
        our plans, we'll send you a secure Stripe payment link. This link will
        allow you to set up and make your recurring monthly payments
        hassle-free.
      </>
    ),
  },
  {
    question: "How can I upgrade my plan?",
    answer:
      "Upgrading your plan is a breeze. Simply get in touch with us, and we'll take care of everything. We'll reconfigure and make any necessary changes to your plan, ensuring it aligns perfectly with your evolving needs. Once everything is set, we'll send you an updated Stripe payment link, making the process seamless and hassle-free.",
  },
  {
    question: "How do the onboarding and offboarding of users work?",
    answer:
      "All companies change over time, and we'll adapt to your changes. We will automatically onboard users as they log in to our system, and you'll be charged in the next billing cycle based on the time they join during the active month. Similarly, if you're offboarding a user, just let us know their last active day at your company, and we will deduct any extra expenses from your next billing.",
  },
  {
    question: "How similar is Ekorn to Bunnylol, GoLinks or YoLinks?",
    answer:
      "While there are commonalities among all these products, Ekorn was developed from the ground up with notable distinctions, including its utilization of GenAI for search, the choice of programming language, and its unique infrastructure stack and logic. Nonetheless, their shared purpose remains consistent: to reduce friction for employees in finding and accessing information.",
  },
];

const FAQsList = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    setUpEntryAnimation(sectionRef);
  }, [sectionRef]);

  return (
    <div id="faqs" className="faq-list-container">
      <TrackableComponent label={"PR | FAQs"} percentage={0.4}>
        <div className="animated-section" ref={sectionRef}>
          <h1 className="faq-title">FAQs</h1>
          <h2 className="faq-subtitle">
            Contact us if your question remains unanswered
          </h2>
        </div>
        {FAQList.map((faq) => (
          <FAQ key={faq.question} faq={faq} />
        ))}
      </TrackableComponent>
    </div>
  );
};

const FAQ = ({ faq }) => {
  const { isSmallScreen } = useContext(EkornAppContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isPlusIcon, setIsPlusIcon] = useState(true);
  const sectionRef = useRef(null);

  useEffect(() => {
    setUpEntryAnimation(sectionRef);
  }, [sectionRef]);

  const toggleCard = () => {
    setIsOpen(!isOpen);
    setTimeout(() => {
      setIsPlusIcon(!isPlusIcon);
    }, 100);
  };

  return (
    <div className="faq-question-container animated-section" ref={sectionRef}>
      <div className="faq-question-row" onClick={toggleCard}>
        {isSmallScreen ? (
          <h3 style={{ margin: "0px" }}>{faq.question}</h3>
        ) : (
          <h2 style={{ margin: "0px" }}>{faq.question}</h2>
        )}
        <div className="faq-question-icon-container">
          <FontAwesomeIcon
            icon={isPlusIcon ? faPlus : faMinus}
            className={`faq-question-icon ${isOpen ? "open" : "closed"}`}
            style={{
              color: "#ffffff",
              height: isSmallScreen ? "16px" : "24px",
            }}
          />
        </div>
      </div>
      <p className={`faq-answer ${isOpen ? "open" : ""}`}>{faq.answer}</p>
    </div>
  );
};

export default FAQsList;
