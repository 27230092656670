import { useContext, useEffect, useRef } from "react";
import "./ProductBenefits.css";
import { setUpEntryAnimation } from "../../utils/AnimationUtils";
import { EkornAppContext } from "../../context/EkornAppContextProvider";
import TrackableComponent from "../../common/TrackableComponent";

const ProductBenefits = () => {
  const { isSmallScreen } = useContext(EkornAppContext);
  const sectionRef = useRef(null);

  useEffect(() => {
    setUpEntryAnimation(sectionRef);
  }, [sectionRef]);

  return (
    <TrackableComponent label={"LP | Product Benefits"}>
      <div
        id="product-benefits"
        className="product-benefits-container animated-section"
        ref={sectionRef}
      >
        <h1 className="ekorn-gradient">
          Ekorn connects you to all your favorite tools
        </h1>
        <div className="product-benefits-integrations">
          <div
            className={`product-benefits-column ${isSmallScreen ? "" : "left"}`}
          >
            <img
              className="product-benefits-integrations-img"
              src="ekorn-integrations.png"
              alt="Ekorn integrations with other web applications"
            />
          </div>
          <div
            className={`product-benefits-column ${
              isSmallScreen ? "" : "right"
            }`}
          >
            <h3 className="product-benefits-text">
              86% of workers struggle to find what they need because information
              is scattered across multiple tools like Jira, Github, Drive etc.
              But there's a better way.
              <br />
              <br />
              Ekorn is a single-search-platform that integrates with all your
              apps (third party and your own internal tools) to provide a
              seamless and fast search experience for your employees.
            </h3>
          </div>
        </div>
      </div>
    </TrackableComponent>
  );
};

export default ProductBenefits;
