import {
  Suspense,
  createRef,
  lazy,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./ProductDemo.css";
import CTAButton from "../../components/CTAButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { getAnalytics, logEvent } from "firebase/analytics";
import { setUpEntryAnimation } from "../../utils/AnimationUtils";
import TrackableComponent from "../../common/TrackableComponent";
import { EkornAppContext } from "../../context/EkornAppContextProvider";

const LazyVideoPlayer = lazy(() => import("./DemoVideoPlayer"));

const ProductDemo = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    setUpEntryAnimation(sectionRef);
  }, [sectionRef]);
  return (
    <div className="product-demo-container animated-section" ref={sectionRef}>
      <img className="product-demo-bubble" src="bubble-background.png" alt="" />
      <h1
        className="ekorn-gradient"
        style={{ position: "relative", marginBottom: "52px" }}
      >
        Curious to see Ekorn in action?
      </h1>
      <DemoVideo />
    </div>
  );
};

const DemoVideo = () => {
  const { isSmallScreen } = useContext(EkornAppContext);
  const [showControls, setShowControls] = useState(false);
  const [showOverlay, setShowOverlay] = useState(true);
  const videoRef = createRef();
  const analytics = getAnalytics();

  const handlePlayButtonClick = () => {
    logEvent(analytics, `demo_video_button_clicked`);
    setShowControls(true);
    setShowOverlay(false);
    videoRef.current.muted = false;
    videoRef.current.currentTime = 0;
    videoRef.current.play();
  };

  return (
    <div className="product-demo-video">
      <div style={{ width: "100%", zIndex: 1, position: "relative" }}>
        <TrackableComponent label={"LP | DemoVideo"}>
          <Suspense fallback={<div>Loading...</div>}>
            <LazyVideoPlayer videoRef={videoRef} showControls={showControls} />
          </Suspense>
          {showOverlay && <div className="product-demo-video-overlay" />}
          {!showControls && (
            <div
              className="product-demo-video-play-button"
              onClick={handlePlayButtonClick}
            >
              <CTAButton
                innerStyle={{
                  color: "white",
                  minWidth: isSmallScreen ? "140px" : "275px",
                  padding: isSmallScreen ? "6px" : "10px",
                }}
              >
                <FontAwesomeIcon
                  icon={faPlay}
                  style={{
                    color: "#ffffff",
                    marginRight: isSmallScreen ? "6px" : "16px",
                    height: isSmallScreen ? "12px" : "16px",
                  }}
                />
                <p
                  style={{
                    display: "initial",
                    fontSize: isSmallScreen ? "12px" : "16px",
                    margin: "0px",
                  }}
                >
                  {isSmallScreen ? "Watch Demo" : "Watch how teams use Ekorn"}
                </p>
              </CTAButton>
            </div>
          )}
        </TrackableComponent>
      </div>
    </div>
  );
};

export default ProductDemo;
