import { getAnalytics, logEvent } from "firebase/analytics";
import {
  ANTONIO_CALENDLY_INVITE,
  JEY_CALENDLY_INVITE,
} from "../../utils/ProductUtils";
import "./BookAMeeting.css";
import { useContext, useEffect, useRef } from "react";
import { setUpEntryAnimation } from "../../utils/AnimationUtils";
import { EkornAppContext } from "../../context/EkornAppContextProvider";
import TrackableComponent from "../../common/TrackableComponent";

const BookAMeeting = () => {
  const analytics = getAnalytics();
  const sectionRef = useRef(null);

  useEffect(() => {
    setUpEntryAnimation(sectionRef);
  }, [sectionRef]);

  return (
    <div
      id="contact"
      className="book-meeting-container animated-section"
      ref={sectionRef}
    >
      <TrackableComponent label={"PR | Let's Talk"}>
        <h1 className="book-meeting-title">Talk to us</h1>
        <h2 className="book-meeting-subtitle">We're here to help</h2>
        <div className="book-meeting-cards-container">
          <BookMeetingCard
            title="Meet Antonio"
            subtitle="Book 30-min meeting"
            imgSrc="antonio-pic-square.jpg"
            onClick={() => {
              logEvent(analytics, "meeting_with_antonio_clicked");
              window.open(ANTONIO_CALENDLY_INVITE, "_blank");
            }}
          />
          <BookMeetingCard
            title="Meet Jey"
            subtitle="Book 30-min meeting"
            imgSrc="jey-pic-square.jpeg"
            onClick={() => {
              logEvent(analytics, "meeting_with_jey_clicked");
              window.open(JEY_CALENDLY_INVITE, "_blank");
            }}
          />
        </div>
      </TrackableComponent>
    </div>
  );
};

const BookMeetingCard = ({ title, subtitle, imgSrc, onClick }) => {
  const { isSmallScreen } = useContext(EkornAppContext);
  return (
    <div className="book-meeting-card" onClick={onClick}>
      <img
        className="book-meeting-profile-pic"
        src={imgSrc}
        alt={`${title}: ${subtitle}`}
      />
      <div className="book-meeting-card-text-container">
        {isSmallScreen ? (
          <h3 style={{ margin: "0px" }}>{title}</h3>
        ) : (
          <h2 style={{ margin: "0px" }}>{title}</h2>
        )}
        <p style={{ margin: "0px" }}>
          <a href="#">{subtitle}</a>
        </p>
      </div>
    </div>
  );
};

export default BookAMeeting;
