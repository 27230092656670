export function setUpEntryAnimation(ref, threshold) {
  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // Add a CSS class to trigger the animation when the section is in the viewport
        entry.target.classList.add("entry-animation");
        // Disconnect the observer after the animation triggers once
        observer.unobserve(entry.target);
      }
    });
  };
  const options = {
    root: null, // Use the viewport as the root
    rootMargin: "0px", // No margin
    threshold: threshold ?? 0.2, // Trigger the animation when 50% of the section is in the viewport
  };

  const observer = new IntersectionObserver(handleIntersection, options);

  if (ref.current) {
    observer.observe(ref.current);
  }

  // Clean up the observer when the component unmounts
  return () => {
    observer.disconnect();
  };
}
