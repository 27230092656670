import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import BasePage from "../BasePage";
import "./AboutPage.css";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useEffect, useRef } from "react";
import { setUpEntryAnimation } from "../../utils/AnimationUtils";

const AboutPage = () => {
  return (
    <BasePage
      pageTitle="Meet our team"
      pageDescription="Inspired by Bunnylol on Facebook/Meta, Golinks on Google, and Yolinks on Yahoo, we built Ekorn with the vision of enhancing efficiency for businesses."
    >
      <AboutPageImpl />
    </BasePage>
  );
};

const AboutPageImpl = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    setUpEntryAnimation(sectionRef);
  }, [sectionRef]);

  return (
    <div className="about-container">
      <h1 className="animated-section" ref={sectionRef}>
        Meet our team
      </h1>
      <div className="about-founder-cards-container">
        <FounderCard
          name="Antonio Jimenez"
          role="Cofounder"
          favoriteShortcut="tr"
          shortcutApp="Google Translate"
          linkedInURL="https://www.linkedin.com/in/aajn88/"
          imgSrc="antonio-pic-square.jpg"
        />
        <FounderCard
          name="Jey Sundaram"
          role="Cofounder"
          favoriteShortcut="sp"
          shortcutApp="Spotify"
          linkedInURL="https://www.linkedin.com/in/jeysundaram/"
          imgSrc="jey-pic-square.jpeg"
        />
      </div>
      <AboutStory />
    </div>
  );
};

const AboutStory = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    setUpEntryAnimation(sectionRef);
  }, [sectionRef]);

  return (
    <p className="about-story animated-section" ref={sectionRef}>
      Our story with Ekorn began with Bunnylol, a simple but powerful tool that
      we used at Meta. Bunnylol helped us save time through browser shortcuts
      that allowed for us to easily, find and share information. Many other tech
      giants like Google, Netflix, Twitter, Stripe, and more rely on tools like
      Bunnylol and GoLinks to boost their productivity and efficiency.
      <br />
      <br />
      We created Ekorn because we realized how valuable Bunnylol was in our
      daily work and how beneficial it could be for other teams and businesses.
      So, we built Ekorn, a version of Bunnylol that anyone can use. We also
      leveraged Generative AI to make Ekorn smarter, easier to user and more
      efficient than ever. Our mission is to share the productivity magic of
      tech giants like FAANG with every team in the world.
    </p>
  );
};

const FounderCard = ({
  name,
  role,
  favoriteShortcut,
  shortcutApp,
  linkedInURL,
  imgSrc,
}) => {
  const analytics = getAnalytics();
  const sectionRef = useRef(null);

  useEffect(() => {
    setUpEntryAnimation(sectionRef);
  }, [sectionRef]);

  return (
    <div className="about-founder-container animated-section" ref={sectionRef}>
      <img className="about-founder-pic" src={imgSrc} alt="" />
      <div className="about-founder-info">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="about-founder-info-section">
            <h4 className="ekorn-gradient-alter">{name}</h4>
            <h4 style={{ fontWeight: 100 }}>{role}</h4>
          </div>
          <FontAwesomeIcon
            className="about-linkedin-icon"
            icon={faLinkedin}
            onClick={() => {
              logEvent(analytics, `about_${name}_linkedin_clicked`);
              window.open(linkedInURL, "_blank");
            }}
          />
        </div>
        <div className="about-founder-info-section fav-shortcut">
          <h4 style={{ fontWeight: 100 }}>Favorite Ekorn Shortcut:</h4>
          <h4 style={{ fontWeight: 100 }}>
            {favoriteShortcut + " "}
            <span style={{ opacity: "50%" }}>[{shortcutApp}]</span>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
