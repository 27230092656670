import React, { createContext, useEffect, useState } from "react";
import { getAPIEndpoint, getPersonalAPIEndpoint } from "../common/Environment";

const EkornAppContext = createContext();

const EkornAppContextProvider = ({ children }) => {
  const [isVerySmallScreen, setIsVerySmallScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [commands, setCommands] = useState({});
  const [user, setUser] = useState();
  const [isCorporateUser, setIsCorporateUser] = useState(false);
  const [baseAPIURL, setBaseAPIURL] = useState(getPersonalAPIEndpoint());
  const [utmLogged, setUTMLogged] = useState(false);

  const onUpdateUser = (user) => {
    setUser(user);
    setIsCorporateUser(user?.enterpriseID ? true : false);
    setBaseAPIURL(getAPIEndpoint(user));
  };

  useEffect(() => {
    const checkScreenSize = () => {
      setIsVerySmallScreen(window.innerWidth < 300);
      setIsSmallScreen(window.innerWidth < 768);
      setIsLargeScreen(window.innerWidth > 1800);
    };

    // Add event listener to window resize
    window.addEventListener("resize", checkScreenSize);

    // Initial check when the component mounts
    checkScreenSize();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  return (
    <EkornAppContext.Provider
      value={{
        isVerySmallScreen,
        isSmallScreen,
        isLargeScreen,
        commands,
        setCommands,
        user,
        setUser: onUpdateUser,
        isCorporateUser,
        baseAPIURL,
        utmLogged,
        setUTMLogged,
      }}
    >
      {children}
    </EkornAppContext.Provider>
  );
};

export { EkornAppContext, EkornAppContextProvider };
