import "./CTAButton.css";

export const ButtonType = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  MENU: "menu",
  ROUNDED_BUTTON: "rounded_button",
};

const CTAButton = ({ children, type, onClick, style, innerStyle, outline }) => {
  const finalType = type ?? ButtonType.PRIMARY;
  let buttonStyle;
  switch (finalType) {
    default:
    case ButtonType.PRIMARY:
      buttonStyle = "primary-cta";
      break;
    case ButtonType.SECONDARY:
      buttonStyle = "secondary-cta";
      break;
    case ButtonType.MENU:
      buttonStyle = "menu-cta";
      break;
    case ButtonType.ROUNDED_BUTTON:
      buttonStyle = `rounded-button-cta ${outline ? "outline" : ""}`;
      break;
  }
  return (
    <div
      className={`outline-gradient-div ${
        finalType === ButtonType.ROUNDED_BUTTON ? "rounded" : ""
      }`}
      style={style}
      onClick={onClick}
    >
      <button className={buttonStyle} style={innerStyle}>
        {children}
      </button>
    </div>
  );
};

export default CTAButton;
