export const STORY_PATH = "/story";
export const ABOUT_PATH = "/about";
export const PRODUCT_PATH = "/#product";
export const PRODUCT_BENEFITS_PATH = "/#product-benefits";
export const PRICING_PATH = "/pricing";
export const FAQS_PATH = "/pricing#faqs";
export const PRIVACY_PATH = "/privacy";
export const CHROME_EXTENSION_URL =
  "https://chrome.google.com/webstore/detail/ekorn-search/kdfjbjabfbfcgenckohbfjoobmaijdmh?hl=en";

export const JEY_CALENDLY_INVITE = "https://calendly.com/jey-ekorn/30min";

export const ANTONIO_CALENDLY_INVITE =
  "https://calendly.com/ekornlabs-antonio/ekorn-demo";
