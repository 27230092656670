import "./PrivacyPage.css";
import BasePage from "./BasePage";

export default function PrivacyPage() {
  return (
    <BasePage
      pageTitle="Privacy"
      pageDescription="Our privacy policy ensures that your information isn't accessed inappropriately and that it's managed responsibly for business purposes."
    >
      <div className="privacy-policy">
        <h1>Privacy Policy</h1>
        <div className="policy-container">
          <p>Last updated: July/11/2023</p>
          <p>
            This Privacy Policy explains how Ekorn ("we" or "us") collects,
            uses, and protects the information obtained from users ("you" or
            "user") of our website{" "}
            <a
              href="https://ekorn.xyz"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://ekorn.xyz
            </a>{" "}
            ("Website") and associated chrome extension ("Extension"). By using
            our Website and Extension, you consent to the terms outlined in this
            Privacy Policy.
          </p>

          <h3>1. Information We Collect</h3>
          <p>
            1.1 Personal Information: When you create a user account on our
            Website, we may collect personal information such as your email
            address and password. Additionally, if you choose to log in through
            Google (or other providers) Single Sign-On (SSO), we may collect
            certain information from your Google account, such as your name and
            email address.
          </p>
          <p>
            1.2 User Activity Data: We may collect information related to your
            usage of our Website and Extension, including but not limited to,
            the pages visited, features accessed, and actions taken. This data
            is collected to analyze and improve the functionality and
            performance of our services.
          </p>
          <p>
            1.3 Error Logs: In order to enhance the reliability and performance
            of our services, we may collect error logs that provide details
            about technical issues or errors encountered by users. These logs
            may contain information such as IP addresses, browser type, and the
            time of occurrence.
          </p>

          <h3>2. Use of Collected Information</h3>
          <p>
            2.1 Essential Purposes: We use the collected information, including
            personal information, for essential purposes such as user
            authentication, providing access to personalized features, and
            maintaining the security of your account.
          </p>
          <p>
            2.2 Analytics and Improvements: We may analyze the collected data to
            gain insights into user behavior, preferences, and trends. This
            information helps us enhance our services, identify and fix errors,
            and optimize user experiences.
          </p>

          <h3>3. Data Retention</h3>
          <p>
            We retain your personal information and collected data for as long
            as necessary to fulfill the purposes outlined in this Privacy
            Policy, unless a longer retention period is required or permitted by
            law.
          </p>

          <h3>4. Data Sharing</h3>
          <p>
            We do not sell, trade, or rent your personal information or
            collected data to third parties. However, we may share certain
            information in the following circumstances:
          </p>
          <p>
            4.1 Service Providers: We may engage trusted third-party service
            providers who assist us in operating our Website and Extension.
            These service providers have access to your information only to
            perform tasks on our behalf and are obligated not to disclose or use
            it for any other purpose.
          </p>
          <p>
            4.2 Legal Requirements: We may disclose your information if required
            to do so by law or in response to valid requests by public
            authorities (e.g., a court or government agency).
          </p>

          <h3>5. Data Security</h3>
          <p>
            We take reasonable measures to protect the security of your personal
            information and collected data. However, no method of transmission
            or electronic storage is 100% secure. Therefore, we cannot guarantee
            absolute security, and you use our services at your own risk.
          </p>

          <h3>6. External Links</h3>
          <p>
            Our Website and Extension may contain links to third-party websites.
            Please note that we have no control over the content or practices of
            these websites. This Privacy Policy applies solely to information
            collected by Ekorn and does not cover any third-party websites. We
            encourage you to review the privacy policies of those third-party
            websites before providing any personal information.
          </p>

          <h3>7. Children's Privacy</h3>
          <p>
            Our services are not directed to individuals under the age of 13. We
            do not knowingly collect personal information from children. If you
            believe that we may have collected information from a child under
            the age of 13, please contact us using the information provided in
            the "Contact Us" section below, and we will promptly delete it.
          </p>

          <h3>8. Changes to this Privacy Policy</h3>
          <p>
            We reserve the right to update or modify this Privacy Policy at any
            time. We will notify you of any changes by posting the updated
            policy on our Website. Your continued use of our services after any
            modifications to the Privacy Policy will signify your acceptance of
            the changes.
          </p>

          <h3>9. Contact Us</h3>
          <p>
            If you have any questions or concerns regarding this Privacy Policy,
            please contact us at{" "}
            <a
              href="mailto:antonio@ekorn.xyz"
              target="_blank"
              rel="noopener noreferrer"
            >
              antonio@ekorn.xyz
            </a>
            .
          </p>
        </div>
      </div>
    </BasePage>
  );
}
