import { getAnalytics, logEvent } from "firebase/analytics";
import { useRef, useEffect, useState } from "react";

function TrackableComponent({ label, percentage = 0.5, children }) {
  const componentRef = useRef(null);
  const [hasTriggered, setHasTriggered] = useState(false);
  const analytics = getAnalytics();

  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: "0px", // No margin needed
      threshold: percentage, // 50% threshold for intersection by default
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (!hasTriggered && entry.isIntersecting) {
          // Check if at least the defined % of the component is visible
          logEvent(analytics, `${percentage * 100}p_visibility: ${label}`);

          setHasTriggered(true);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      // Cleanup the observer when the component unmounts
      observer.disconnect();
    };
  }, [label, hasTriggered, percentage]);

  return <div ref={componentRef}>{children}</div>;
}

export default TrackableComponent;
