import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import "./FeatureList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FeatureList = ({ features }) => {
  return (
    <div className="feature-list">
      {features.map((feature) => (
        <div
          key={feature.name}
          style={{ display: "flex", alignItems: "center" }}
        >
          {feature.isExcluded ? (
            <FontAwesomeIcon
              icon={faXmark}
              style={{ color: "red", minWidth: "14px" }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faCheck}
              style={{ color: "#50a584", minWidth: "14px" }}
            />
          )}
          {feature.isHighlighted ? (
            <p
              className="rotating-text"
              style={{
                margin: "0px 8px",
                fontWeight: 900,
                color: "transparent",
              }}
            >
              {feature.name}
            </p>
          ) : (
            <p style={{ margin: "0px 8px", fontWeight: 300 }}>{feature.name}</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default FeatureList;
