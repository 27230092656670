const APP_MAP = {
  tool: { icon: "tools-icon.png" },
  confluence: { icon: "confluence-icon.png" },
  incident: { icon: "fire-icon.png" },
  github: { icon: "github-icon.png" },
  slack: { icon: "slack-icon.png" },
  gdocs: { icon: "gdocs-icon.png" },
  default: { icon: "EkornSq192.png" },
};

export function getAppMetadata(appID) {
  return APP_MAP[appID] ?? APP_MAP.default;
}
