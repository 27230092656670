import "./SearchResult.css";

export default function SearchResult({ imageSrc, title, url, description }) {
  const handleOnClick = (e) => {
    const isModifierKeyPressed = e.metaKey || e.ctrlKey;

    // If the event target is not the <a> element, open the URL
    if (e.target.tagName.toLowerCase() !== "a") {
      e.preventDefault();
      if (isModifierKeyPressed) {
        window.open(url, "_blank");
      } else {
        window.location.href = url;
      }
    }
  };

  return (
    <div className="search-result" onClick={handleOnClick}>
      <img src={imageSrc} alt={title} className="result-image" />
      <div className="result-content">
        <a href={url} className="result-title">
          {title}
        </a>
        <p className="result-description">{description}</p>
      </div>
    </div>
  );
}
