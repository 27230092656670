import SearchResult from "../components/SearchResult";
import "./SearchPage.css";
import algoliasearch from "algoliasearch/lite";
import {
  Hits,
  InstantSearch,
  Highlight,
  Pagination,
  Configure,
  Snippet,
  useSearchBox,
} from "react-instantsearch-hooks-web";
import "instantsearch.css/themes/satellite.css";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import SearchBox from "../components/SearchBox";
import FilterList from "../components/FilterList";
import { getAppMetadata } from "../utils/SearchUtils";
import BasePage from "./BasePage";

const searchClient = algoliasearch(
  "W8HLK3AX1E",
  "6aabd576366410cb054338b442c2c53c"
);

export default function SearchPage() {
  const [searchParams, _setSearchParams] = useSearchParams();
  const query = searchParams.get("q");
  const rawFilters = searchParams.get("filters");
  const filters = rawFilters?.split(",");

  return (
    <BasePage
      showTopBar={false}
      pageTitle="Search"
      pageDescription="Integrated and centralized search for company data. One place, one search. Take the best out of it by using Ekorn shortcuts"
    >
      <div className="search-container">
        <InstantSearch
          searchClient={searchClient}
          indexName="ekorn_prod"
          initialUiState={{
            ekorn_prod: {
              refinementList: { source: filters },
            },
          }}
          insights
        >
          <SearchContainer query={query} />
        </InstantSearch>
      </div>
    </BasePage>
  );
}

function SearchContainer({ query }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { refine } = useSearchBox();
  useEffect(() => refine(query), [query]);
  const onSubmitSearch = (newQuery) => {
    searchParams.set("q", newQuery);
    setSearchParams(searchParams);
  };
  return (
    <>
      <Configure hitsPerPage={10} />
      <div className="search-box-container">
        <SearchBox onSubmit={onSubmitSearch} initialQuery={query} />
      </div>
      <FilterList attribute="source" />
      <div className="results-container">
        <Hits hitComponent={Hit} />
      </div>
      <Pagination />
    </>
  );
}

function Hit({ hit }) {
  const appMetadata = getAppMetadata(hit.source);

  const titleComponent = <Highlight attribute="title" hit={hit} />;
  const descriptionComponent = <Snippet attribute="content" hit={hit} />;

  return (
    <SearchResult
      key={hit.objectID}
      imageSrc={appMetadata.icon}
      title={titleComponent}
      url={hit.url}
      description={descriptionComponent}
    />
  );
}
