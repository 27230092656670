import React, { useContext, useEffect, useRef } from "react";
import ProductBenefits from "./ProductBenefits";
import "./ProductDetails.css";
import { setUpEntryAnimation } from "../../utils/AnimationUtils";
import { EkornAppContext } from "../../context/EkornAppContextProvider";
import ProductDemo from "./ProductDemo";
import TrackableComponent from "../../common/TrackableComponent";

const EXAMPLES_METADATA = [
  {
    title: "Discover the power of shortcuts",
    description:
      "Swap out complex URLs and forgotten bookmarks with our intuitive, user-friendly 'shortcuts'.",
    examples: [
      { shortcut: "tickets", logoSrc: "jira-icon.png" },
      { shortcut: "git", logoSrc: "github-icon.png" },
      { shortcut: "crm", logoSrc: "salesforce-icon.webp" },
    ],
  },
  {
    title: "Go faster with search terms",
    description:
      "Use IDs or a 'search term' with an Ekorn shortcut to take you directly to the subpage you're looking for.",
    examples: [
      {
        shortcut: "wiki",
        query: "onboarding engs",
        logoSrc: "confluence-icon.png",
      },
      { shortcut: "git", query: "server.ts", logoSrc: "github-icon.png" },
      {
        shortcut: "crm",
        query: "425317562323",
        logoSrc: "salesforce-icon.webp",
      },
    ],
  },
  {
    title: "Conversational search powered by GenAI",
    description:
      "Our 'ai' shortcut leads you to the tool or information you requested, based on your list of shortcuts.",
    examples: [
      {
        shortcut: "ai",
        query: "What's the tool for tickets?",
        smallQuery: "tool for tickets",
        logoSrc: "jira-icon.png",
      },
      {
        shortcut: "ai",
        query: "get me to the code base",
        smallQuery: "go to codebase",
        logoSrc: "github-icon.png",
      },
      {
        shortcut: "ai",
        query: "where do I book holidays?",
        smallQuery: "book holidays",
        logoSrc: "workday-icon.png",
      },
    ],
  },
];

const ProductDetails = () => {
  const { isSmallScreen } = useContext(EkornAppContext);
  return (
    <div className="product-details-container">
      {EXAMPLES_METADATA.map((exampleMetadata, index) => {
        const isReversed = !isSmallScreen && index % 2 !== 0;
        return (
          <SectionRenderer
            key={index}
            exampleMetadata={exampleMetadata}
            isReversed={isReversed}
          />
        );
      })}
      <ProductDemo />
      <SocialProof />
      <ProductBenefits />
    </div>
  );
};

const SocialProof = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    setUpEntryAnimation(sectionRef);
  }, [sectionRef]);
  return (
    <TrackableComponent label={"LP | Social Proof"}>
      <div
        className="product-details-social-proof animated-section"
        ref={sectionRef}
      >
        <div className="product-details-social-proof-profile-pic-container">
          <img
            className="product-details-social-proof-background"
            src="bubble-background.png"
            alt=""
          />
          <img
            className="product-details-social-proof-profile-pic"
            src="antonio-pic-square.jpg"
            alt="Antonio's Ekorn profile pic"
          />
        </div>
        <div className="product-details-social-proof-quote-container">
          <img
            className="product-details-social-proof-quotes"
            height={60}
            src="quotes.png"
            alt=""
          />
          <h3 style={{ marginBottom: "30px", maxWidth: "600px" }}>
            Discovering bunnylol at Meta was a game changer for me and that's
            precisely why I founded Ekorn - to share the transformative impact
            it has on people's daily work with other companies
          </h3>
          <h3 className="ekorn-gradient">Antonio Jimenez</h3>
          <h3 style={{ color: "white", opacity: "60%", marginTop: "6px" }}>
            Founder, Ekorn
            <br />
            SWE @ Meta
          </h3>
        </div>
      </div>
    </TrackableComponent>
  );
};

const SectionRenderer = ({ exampleMetadata, isReversed }) => {
  const sectionRef = useRef(null);

  useEffect(() => {
    setUpEntryAnimation(sectionRef);
  }, [sectionRef]);

  return (
    <div
      className="product-details-section-container animated-section"
      ref={sectionRef}
    >
      <Section exampleMetadata={exampleMetadata} isReversed={isReversed} />
      <BlurCircle isReversed={isReversed} />
    </div>
  );
};

const BlurCircle = ({ isReversed }) => {
  const circleStyles = {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50px",
    zIndex: 0,
  };
  if (isReversed) {
    circleStyles.right = "-500px";
  } else {
    circleStyles.left = "250px";
  }
  return <img style={circleStyles} height={700} src="CircleBlur.png" alt="" />;
};

const Section = ({ exampleMetadata, isReversed }) => {
  const { isSmallScreen } = useContext(EkornAppContext);
  const { title, description, examples } = exampleMetadata;
  const examplesComponent = (
    <EkornExamples examples={examples} isReversed={isReversed} />
  );
  const sectionExplanation = (
    <SectionExplanation
      title={title}
      description={description}
      isReversed={isReversed}
    />
  );
  const horizontalPadding = isSmallScreen ? "0px" : "36px";
  return (
    <TrackableComponent label={`LP | ${title}`}>
      <div className="product-details-section">
        <div
          className="product-details-column"
          style={{ paddingRight: horizontalPadding }}
        >
          {isReversed ? sectionExplanation : examplesComponent}
        </div>
        <div
          className="product-details-column"
          style={{ paddingLeft: horizontalPadding }}
        >
          {isReversed ? examplesComponent : sectionExplanation}
        </div>
      </div>
    </TrackableComponent>
  );
};

const SectionExplanation = ({ title, description, isReversed }) => {
  return (
    <div
      className={`product-details-explanation-container ${
        isReversed ? "reversed" : ""
      }`}
    >
      <h1
        style={{
          margin: "0px",
          marginBottom: "16px",
          letterSpacing: "2px",
          fontWeight: 100,
        }}
      >
        {title}
      </h1>
      <h3
        style={{
          margin: "0px",
          marginBottom: "8px",
          fontWeight: 100,
          maxWidth: "350px",
        }}
      >
        {description}
      </h3>
    </div>
  );
};

const EkornExamples = ({ examples, isReversed }) => {
  return (
    <div
      className={`product-details-examples-container ${
        isReversed ? "reversed" : ""
      }`}
    >
      {examples.map((example, index) => (
        <EkornExample
          key={index}
          example={example}
          isLastOne={index === examples.length - 1}
        />
      ))}
    </div>
  );
};

const EkornExample = ({ example, isLastOne }) => {
  const { isSmallScreen } = useContext(EkornAppContext);
  const { shortcut, query, smallQuery, logoSrc } = example;
  const queryComponent = query && (
    <span style={{ fontWeight: "400" }}>
      {isSmallScreen ? smallQuery ?? query : query}
    </span>
  );
  return (
    <div className="product-details-browser-container">
      <div
        className="product-details-browser"
        style={{ marginBottom: isLastOne ? "0px" : "12px" }}
      >
        <div className="product-details-browser-circles" />
        <div className="product-details-browser-circles" />
        <div className="product-details-browser-circles" />
        <img src={logoSrc} height={20} alt="" style={{ marginLeft: "8px" }} />
        <div className="product-details-browser-input-text">
          {shortcut}
          {queryComponent && "\u00A0"}
          {queryComponent}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
