import { getAnalytics, logEvent } from "firebase/analytics";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { EkornAppContext } from "./context/EkornAppContextProvider";

const UTMTracker = () => {
  const location = useLocation();
  const analytics = getAnalytics();
  const { utmLogged, setUTMLogged } = useContext(EkornAppContext);

  useEffect(() => {
    if (utmLogged) {
      return;
    }
    const urlSearchParams = new URLSearchParams(location.search);
    const utmSource = urlSearchParams.get("utm_source");
    const utmMedium = urlSearchParams.get("utm_medium");
    const utmCampaign = urlSearchParams.get("utm_campaign");

    logEvent(analytics, `utm_source_${utmSource}`);
    logEvent(analytics, `utm_medium_${utmMedium}`);
    logEvent(analytics, `utm_campaign_${utmCampaign}`);

    setUTMLogged(true);
  }, [location.search, analytics, utmLogged, setUTMLogged]);

  return <></>;
};

export default UTMTracker;
