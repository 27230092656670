import React, { useEffect, useState } from "react";
import "./SearchBox.css";

function SearchBox({ onSubmit, initialQuery }) {
  const [query, setQuery] = useState(initialQuery ?? "");
  useEffect(() => setQuery(initialQuery), [initialQuery]);
  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };
  const handleOnSubmit = (event) => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit(query);
    }
  };
  return (
    <form className="search-box" onSubmit={handleOnSubmit}>
      <input
        type="text"
        placeholder="Search"
        value={query}
        onChange={handleQueryChange}
      />
      <button type="submit">Search</button>
    </form>
  );
}

export default SearchBox;
