import BasePage from "../BasePage";
import "./StoryPage.css";

const StoryPage = () => {
  return (
    <BasePage
      pageTitle="Origin of Ekorn"
      pageDescription="The Origin of Ekorn: How Bunnylol and Go/Links Transformed Our Journey."
    >
      <StoryPageImpl />
    </BasePage>
  );
};

const StoryPageImpl = () => {
  return (
    <div className="story-container">
      <h1>
        The Origin of Ekorn: How Bunnylol and Go/Links Transformed Our Journey
      </h1>
      <p>
        In the fast-paced world of tech innovation, it's not uncommon for a
        simple idea to spark a revolution. For us at Ekorn, that spark was
        Bunnylol - a small yet mighty tool that changed the way we work and laid
        the foundation for our mission to empower teams worldwide with
        productivity-enhancing solutions.
      </p>
      <h2>The Bunnylol Revelation</h2>
      <p>
        Our story begins with Bunnylol, a tool that initially found its place
        within the walls of Meta. At Meta, we were on a relentless quest to
        streamline our processes and boost productivity. In the midst of this
        mission, Bunnylol emerged as a game-changer.
      </p>
      <p>
        Bunnylol was more than just a clever name; it was a simple but powerful
        tool that revolutionized our workflows. Through ingenious browser
        shortcuts, Bunnylol enabled us to swiftly locate and share critical
        information. The time savings were remarkable, and soon, we couldn't
        imagine working without it.
      </p>
      <h2>Spreading the Magic</h2>
      <p>
        Word of Bunnylol's prowess quickly spread beyond the confines of Meta.
        Tech giants such as Google, Netflix, Twitter, Stripe, and many others
        recognized its potential and incorporated similar tools into their daily
        operations. Bunnylol was, in essence, a productivity booster that had
        transcended company boundaries.
      </p>
      <p>
        But we saw something more profound in Bunnylol. We saw the
        transformative potential it held for teams and businesses worldwide.
        Thus, the seed for Ekorn was planted - a vision to make Bunnylol
        accessible to everyone.
      </p>
      <h2>Ekorn: Inspired by Bunnylol, Built from Scratch</h2>
      <p>
        With unwavering determination, we embarked on the journey of building
        Ekorn. While Ekorn drew inspiration from the incredible utility of
        Bunnylol, it was far from being a mere copy. Ekorn was crafted from the
        ground up, with innovation at its core.
      </p>
      <p>
        We leveraged the power of Generative AI and poured countless hours into
        development to make Ekorn smarter, user-friendlier, and more efficient
        than ever. It wasn't about replicating Bunnylol; it was about taking the
        essence of what made it great and creating something entirely new.
      </p>
      <p>
        Our mission was clear: we wanted to share the productivity magic wielded
        by tech giants like FAANG with teams all over the globe through a unique
        and evolved tool. Ekorn was the embodiment of that vision, a fresh
        perspective on productivity tools that would revolutionize the way teams
        work.
      </p>
      <h2>The Journey Ahead</h2>
      <p>
        As Ekorn takes its first steps in the world of productivity tools, we
        recognize that our story is still unfolding. While we may not have
        achieved widespread recognition or a massive client base just yet, our
        passion and dedication remain undiminished.
      </p>
      <p>
        We are fully aware that building a brand-new tool and gaining traction
        in the competitive tech landscape is no small feat. However, our belief
        in the transformative power of Ekorn is what fuels our determination to
        push forward. We're committed to growing alongside our clients,
        continuously refining and improving Ekorn to meet their needs.
      </p>
      <p>
        As we continue this journey, we invite you to be a part of our story.
        Your support, feedback, and insights are invaluable as we strive to make
        Ekorn an indispensable asset for teams and businesses seeking
        productivity enhancements. Together, we are shaping the future of Ekorn
        and paving the way for a more productive work environment.
      </p>

      <div className="story-signature">- Antonio, Co-founder @ Ekorn</div>
    </div>
  );
};

export default StoryPage;
