import { useRefinementList } from "react-instantsearch-hooks-web";
import ToggleChip from "./ToggleChip";
import { getAppMetadata } from "../utils/SearchUtils";
import "./FilterList.css";
import { useSearchParams } from "react-router-dom";

const FilterList = (props) => {
  const { items, refine } = useRefinementList(props);
  return (
    <div style={{ display: "flex", marginBottom: "16px" }}>
      {items.map((item) => (
        <FilterItem key={item.value} item={item} refine={refine} />
      ))}
    </div>
  );
};

const FilterItem = ({ item, refine }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const rawFilters = searchParams.get("filters");
  const filters = rawFilters?.split(",") ?? [];
  const label = item.label;
  const value = item.value;
  const appMetadata = getAppMetadata(value);

  const handleOnClick = () => {
    let newFilters = filters;
    if (newFilters.includes(value)) {
      newFilters = newFilters.filter((v) => v !== value);
    } else {
      newFilters.push(value);
    }
    if (newFilters.length > 0) {
      searchParams.set("filters", newFilters);
    } else {
      searchParams.delete("filters");
    }
    setSearchParams(searchParams);
    // Even though we update the params, we still need
    // to do refine as the components won't reload
    // We just keep the params in sync for sharing
    // and refreshing
    refine(item.value);
  };
  const transformedLabel =
    label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
  return (
    <ToggleChip
      style={{ marginRight: "14px" }}
      onClick={handleOnClick}
      active={item.isRefined}
    >
      <img
        src={appMetadata.icon}
        alt={transformedLabel}
        className="toggle-image"
      />
      <div className="text">{transformedLabel}</div>
    </ToggleChip>
  );
};

export default FilterList;
