import "./Footer.css";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useNavigate } from "react-router-dom";
import {
  PRODUCT_PATH,
  JEY_CALENDLY_INVITE,
  PRODUCT_BENEFITS_PATH,
  FAQS_PATH,
  ABOUT_PATH,
  PRIVACY_PATH,
  PRICING_PATH,
  STORY_PATH,
} from "./utils/ProductUtils";
import { PageContext } from "./context/PageContextProvider";
import { useContext } from "react";
import TrackableComponent from "./common/TrackableComponent";

const Footer = () => {
  const { openContactUsPopup } = useContext(PageContext);

  const FOOTER_LINKS = [
    {
      title: "Product",
      links: [
        { title: "How it works", url: PRODUCT_PATH },
        { title: "Benefits", url: PRODUCT_BENEFITS_PATH },
        { title: "Pricing", url: PRICING_PATH },
      ],
    },
    {
      title: "Support",
      links: [
        { title: "FAQs", url: FAQS_PATH },
        { title: "Book a Demo", url: JEY_CALENDLY_INVITE },
        {
          title: "Contact Us",
          onClick: openContactUsPopup,
        },
      ],
    },
    {
      title: "About",
      links: [
        { title: "Our Team", url: ABOUT_PATH },
        { title: "Privacy", url: PRIVACY_PATH },
        { title: "Our Story", url: STORY_PATH },
      ],
    },
  ];
  const currentPath = window.location.pathname;

  return (
    <footer className="footer">
      <div className="footer-content-container">
        {FOOTER_LINKS.map((footerLink, index) => (
          <FooterColumn
            key={index}
            title={footerLink.title}
            links={footerLink.links}
          />
        ))}
      </div>
      <TrackableComponent label={`${currentPath} | Footer`} />
    </footer>
  );
};

const FooterColumn = ({ title, links }) => {
  const navigate = useNavigate();
  const analytics = getAnalytics();
  return (
    <div className="footer-column">
      <h4 style={{ margin: "0px", marginBottom: "20px", fontSize: "0.9em" }}>
        {title.toUpperCase()}
      </h4>
      {links.map((link, index) => (
        <h4
          key={index}
          style={{
            margin: "0px",
            marginBottom: "12px",
            fontWeight: 100,
            fontSize: "0.9em",
          }}
        >
          <a
            className="footer-link"
            onClick={() => {
              logEvent(analytics, `footer_${title}_clicked`);
              if (link.onClick) {
                link.onClick();
              } else if (link.url.startsWith("/")) {
                navigate(link.url);
              } else {
                window.open(link.url, "_blank");
              }
            }}
          >
            {link.title}
          </a>
        </h4>
      ))}
    </div>
  );
};

export default Footer;
