import { getAnalytics, logEvent } from "firebase/analytics";
import CTAButton, { ButtonType } from "../../components/CTAButton";
import "./FinalPromo.css";
import { setUpEntryAnimation } from "../../utils/AnimationUtils";
import {
  CHROME_EXTENSION_URL,
  JEY_CALENDLY_INVITE,
} from "../../utils/ProductUtils";
import { useContext, useEffect, useRef } from "react";
import { EkornAppContext } from "../../context/EkornAppContextProvider";
import TrackableComponent from "../../common/TrackableComponent";

const FinalPromo = () => {
  return (
    <TrackableComponent label={"LP | Final Promo Cards"}>
      <div className="final-promo-container">
        <PromoCard
          title="GET STARTED"
          description="Try Ekorn for free via our Chrome extension"
          cta="Try it Free"
          url={CHROME_EXTENSION_URL}
          imgSrc="chrome-store-icon.png"
          outline
        />
        <PromoCard
          title="SEE OUR DEMO"
          description="We'll demo Ekorn and answer any questions you may have "
          cta="Book a Demo"
          url={JEY_CALENDLY_INVITE}
          imgSrc="google-meet-logo.png"
        />
      </div>
    </TrackableComponent>
  );
};

const PromoCard = ({ title, description, cta, url, imgSrc, outline }) => {
  const analytics = getAnalytics();
  const sectionRef = useRef(null);
  const { isVerySmallScreen } = useContext(EkornAppContext);

  useEffect(() => {
    setUpEntryAnimation(sectionRef);
  }, [sectionRef]);
  return (
    <div className="final-promo-card animated-section" ref={sectionRef}>
      <div className="final-promo-card-left-section">
        <h3>{title}</h3>
        <p>{description}</p>
        <div style={{ display: "flex" }}>
          <CTAButton
            type={ButtonType.ROUNDED_BUTTON}
            onClick={() => {
              logEvent(analytics, `final_promo_${title}_clicked`);
              window.open(url, "_blank");
            }}
            outline={outline}
            innerStyle={outline ? { background: "#302e40" } : undefined}
          >
            {cta}
          </CTAButton>
        </div>
      </div>
      {!isVerySmallScreen && (
        <div style={{ display: "flex", opacity: "50%" }}>
          <img className="final-promo-card-img" src={imgSrc} alt="" />
        </div>
      )}
    </div>
  );
};

export default FinalPromo;
