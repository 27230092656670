import { getAnalytics, logEvent } from "firebase/analytics";
import axios from "axios";
import { getPersonalAPIEndpoint } from "../common/Environment";
import Popup from "./Popup";
import "./ContactUsPopup.css";
import { useContext, useState } from "react";
import { PageContext } from "../context/PageContextProvider";
import { FAQS_PATH } from "../utils/ProductUtils";

export default function ContactUsPopup() {
  const { closeContactUsPopup } = useContext(PageContext);
  const analytics = getAnalytics();
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [nameStartedTyping, setNameStartedTyping] = useState(false);
  const [companyStartedTyping, setCompanyStartedTyping] = useState(false);
  const [emailStartedTyping, setEmailStartedTyping] = useState(false);
  const [messageStartedTyping, setMessageStartedTyping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const apiUrl = getPersonalAPIEndpoint();

  const handleClosePopup = () => {
    setShowSuccessMessage(false);
    closeContactUsPopup();
  };

  const submitEmailToBeta = (event) => {
    event.preventDefault();

    logEvent(analytics, "contact_us_submit_clicked");

    setErrorMessage(undefined);
    setShowSuccessMessage(false);
    setIsLoading(true);
    axios
      .post(`${apiUrl}/contact-us`, {
        name,
        company,
        email,
        message,
      })
      .then((_response) => {
        logEvent(analytics, "contact_us_submit_confirmation");
        setShowSuccessMessage(true);
        setIsLoading(false);
      })
      .catch((error) => {
        var errorMessage = "Unexpected Error.";
        if (error.response && error.response.data.error) {
          errorMessage = error.response.data.error;
        }
        logEvent(analytics, "contact_us_submit_error", {
          name,
          email,
          company,
          message,
          error_message: errorMessage,
        });
        setErrorMessage(errorMessage);
        setIsLoading(false);
      });
  };
  const handleNameChange = (event) => {
    if (!nameStartedTyping) {
      logEvent(analytics, "name_started_typing");
      setNameStartedTyping(true);
    }
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    if (!emailStartedTyping) {
      logEvent(analytics, "email_started_typing");
      setEmailStartedTyping(true);
    }
    setEmail(event.target.value);
  };

  const handleCompanyChange = (event) => {
    if (!companyStartedTyping) {
      logEvent(analytics, "company_started_typing");
      setCompanyStartedTyping(true);
    }
    setCompany(event.target.value);
  };

  const handleMessageChange = (event) => {
    if (!messageStartedTyping) {
      logEvent(analytics, "message_started_typing");
      setMessageStartedTyping(true);
    }
    setMessage(event.target.value);
  };

  return (
    <Popup className="contact-us-popup" closePopup={handleClosePopup}>
      <div className="contact-us-popup-content">
        <h2 className="contact-us-popup-title">CONTACT US</h2>
        <p className="contact-us-popup-subtitle">
          Drop us a message and we'll get back to you soon.
          <br />
          While you wait, why not checkout our{" "}
          <a href={FAQS_PATH} target="_blank" rel="noopener noreferrer">
            FAQs
          </a>
          ?
        </p>
        <form className="contact-us-popup-form" onSubmit={submitEmailToBeta}>
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={handleNameChange}
            required
          />
          <input
            type="text"
            placeholder="Company"
            value={company}
            onChange={handleCompanyChange}
            required
          />
          <input
            type="email"
            placeholder="Your email"
            value={email}
            onChange={handleEmailChange}
            required
          />
          <textarea
            type="text"
            placeholder="Message"
            value={message}
            onChange={handleMessageChange}
            rows={5}
            required
          />
          <button
            type="submit"
            className="rounded-button-cta"
            style={{
              color: "white",
              margin: "16px 0px",
              alignSelf: "flex-end",
              background: "linear-gradient(45deg, #a18eed 0%, #14BB68 100%)",
            }}
            disabled={isLoading}
          >
            {isLoading ? "Saving..." : "Submit Message"}
          </button>
        </form>
        {showSuccessMessage && (
          <p className="contact-us-success-message">
            Thanks for getting in contact! We'll get back to you asap.
          </p>
        )}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
    </Popup>
  );
}
