import { useEffect, useRef } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";
import LandingPageAnimation from "../../components/LandingPageAnimation";
import "./LandingPage.css";
import BasePage from "../BasePage";
import {
  CHROME_EXTENSION_URL,
  JEY_CALENDLY_INVITE,
} from "../../utils/ProductUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import CTAButton, { ButtonType } from "../../components/CTAButton";
import ProductDetails from "./ProductDetails";
import { setUpEntryAnimation } from "../../utils/AnimationUtils";
import FinalPromo from "./FinalPromo";

const QUESTIONS = [
  "ai Where's the sales deck?",
  "git server.ts",
  "tickets",
  "wiki onboarding for engs",
  "roadmap",
  "ai Where do I book holidays?",
  "crm 437642387462",
  "ai Show me the roadmap",
];

export default function LandingPage() {
  return (
    <BasePage
      pageTitle="Smart shortcuts and AI-powered search"
      pageDescription="Smart shortcuts and AI-powered search to find and share information. Inspired by the much-loved bunnylol on Facebook/Meta, Golinks at Google and Yolinks at Yahoo."
    >
      <LandingPageContent />
    </BasePage>
  );
}

function LandingPageContent() {
  const analytics = getAnalytics();
  const sectionRef = useRef(null);

  useEffect(() => {
    setUpEntryAnimation(sectionRef);
  }, [sectionRef]);

  return (
    <>
      <div
        className="first-page-container landing animated-section"
        ref={sectionRef}
      >
        <div className="landing-page-search-bar">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="magnifying-icon"
          />
          <LandingPageAnimation
            words={QUESTIONS}
            animationInterval={40}
            pauseBeforeEraseInterval={800}
            makeFirstWorkPrefix={true}
          />
        </div>
        <div className="landing-page-tagline">
          <h3 style={{ marginTop: "28px", marginBottom: "8px" }}>
            Smart shortcuts and AI-powered search to find and share information.
          </h3>
          <h3 style={{ marginTop: "0px" }}>
            Team efficiency is the key to success for FAANG - what's yours?
          </h3>
        </div>
        <div
          style={{ marginTop: "28px", display: "flex", marginBottom: "100px" }}
        >
          <CTAButton
            type={ButtonType.SECONDARY}
            style={{ marginRight: "16px", width: "100%" }}
            onClick={() => {
              logEvent(analytics, "try_free_button_clicked");
              window.open(CHROME_EXTENSION_URL, "_blank");
            }}
          >
            Try it free
          </CTAButton>
          <CTAButton
            style={{ marginLeft: "16px", width: "100%" }}
            onClick={() => {
              logEvent(analytics, "meeting_with_jey_clicked");
              window.open(JEY_CALENDLY_INVITE, "_blank");
            }}
          >
            Book a Demo
          </CTAButton>
        </div>
      </div>
      <div id="product" className="first-page-container">
        <ProductDetails />
        <FinalPromo />
      </div>
    </>
  );
}
