import React, { useContext, useState } from "react";
import "./Commands.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { AddCommandPopup } from "./AddCommandPopup";
import { EkornAppContext } from "../context/EkornAppContextProvider";

const styles = {
  tooltip: { fontSize: "12px" },
};

const Commands = () => {
  const { commands, user } = useContext(EkornAppContext);

  const cols = ["Shortcut", "Name", "Description", "Type"];

  const rows = Object.entries(commands).map(([_key, cmd]) => cmd);
  rows.sort((cmdA, cmdB) => cmdA.command.localeCompare(cmdB.command));

  return (
    <table className={["table", "table-striped", "custom-table"].join(" ")}>
      <thead>
        <tr>
          {cols.map((col) => (
            <th key={col}>{col}</th>
          ))}
          {user && <th key="edit" />}
          {user && <th key="delete" />}
        </tr>
      </thead>
      <tbody>
        {rows.map((cmd) => (
          <RowCommand key={cmd.command} cmd={cmd} user={user} />
        ))}
      </tbody>
    </table>
  );
};

const RowCommand = ({ cmd, user }) => {
  const { baseAPIURL } = useContext(EkornAppContext);
  const commandType = cmd.type ?? "PERSONAL";
  let type;
  switch (commandType) {
    case "CORPORATE":
      type = "Company";
      break;
    case "PERSONAL":
      type = "Personal";
      break;
    default:
    case "DEFAULT":
      type = "Default";
      break;
  }
  const [showEditCommandPopup, setShowEditCommandPopup] = useState(false);
  const [commandToEdit, setCommandToEdit] = useState();

  const handleSubmit = (e) => {
    const answer = window.confirm(
      "Are you sure you want to delete this command?"
    );
    if (!answer) {
      // We prevent behavior if not affirmative.
      e.preventDefault();
    }
  };

  const handleEdit = (cmd) => {
    setCommandToEdit(cmd);
    setShowEditCommandPopup(true);
  };
  const closeEditCommandPopup = () => setShowEditCommandPopup(false);

  return (
    <>
      <tr key={cmd.command}>
        <td>
          {/* eslint-disable-next-line */}
          <a href="#">{cmd.command}</a>
        </td>
        <td className="text-column">{cmd.name}</td>
        <td className="text-column">{cmd.description}</td>
        <td>{type}</td>
        {user && commandType !== "DEFAULT" && (
          <td className="actions-cell">
            <button
              type="submit"
              className="btn-edit commands-button"
              onClick={() => handleEdit(cmd)}
            >
              <i className="fas fa-pen" />
            </button>
            <form
              action={`${baseAPIURL}/delete`}
              method="POST"
              style={{ display: "inline" }}
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="id" value={cmd.id} />
              <input type="hidden" name="cmd" value={cmd.command} />
              <button type="submit" className="btn-delete commands-button">
                <i className="fas fa-trash" />
              </button>
            </form>
          </td>
        )}
        <ReactTooltip
          id={cmd.emptyAction}
          content={cmd.emptyAction}
          style={styles.tooltip}
        />
        <ReactTooltip
          id={cmd.actionWithArguments}
          content={cmd.actionWithArguments}
          style={styles.tooltip}
        />
      </tr>
      {showEditCommandPopup && (
        <AddCommandPopup
          closePopup={closeEditCommandPopup}
          commandToEdit={commandToEdit}
        />
      )}
    </>
  );
};

export default Commands;
