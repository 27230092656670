import React, { useState, useEffect } from "react";
import "./LandingPageAnimation.css";

const DIRECTION = {
  FORWARD: "FORWARD",
  BACKWARD: "BACKWARD",
  PAUSE: "PAUSE",
};

export default function LandingPageAnimation({
  prefixWord,
  words,
  animationInterval,
  pauseBeforeEraseInterval,
  makeFirstWorkPrefix,
}) {
  const [currentWord, setCurrentWord] = useState("");
  const [typingDirection, setTypingDirection] = useState(DIRECTION.FORWARD);
  const [wordIndex, setWordIndex] = useState(0);

  useEffect(() => {
    let intervalTime =
      animationInterval ?? (words[wordIndex].length > 6 ? 110 : 140);
    if (pauseBeforeEraseInterval && typingDirection === DIRECTION.PAUSE) {
      intervalTime = pauseBeforeEraseInterval;
    }
    const interval = setInterval(() => {
      switch (typingDirection) {
        default:
        case DIRECTION.FORWARD:
          if (currentWord.length < words[wordIndex].length) {
            setCurrentWord(
              (prevWord) => prevWord + words[wordIndex][prevWord.length]
            );
          } else {
            setTypingDirection(
              pauseBeforeEraseInterval ? DIRECTION.PAUSE : DIRECTION.BACKWARD
            );
          }
          break;
        case DIRECTION.BACKWARD:
          if (currentWord.length > 0) {
            setCurrentWord((prevWord) => prevWord.slice(0, -1));
          } else {
            setWordIndex((wordIndex + 1) % words.length);
            setTypingDirection(DIRECTION.FORWARD);
          }
          break;
        case DIRECTION.PAUSE:
          setTypingDirection(DIRECTION.BACKWARD);
          break;
      }
    }, intervalTime);

    return () => clearInterval(interval);
  }, [
    currentWord,
    typingDirection,
    words,
    wordIndex,
    animationInterval,
    pauseBeforeEraseInterval,
  ]);

  if (!makeFirstWorkPrefix) {
    return (
      <h1 className="landing-page-animation-text">
        <span className="ekorn-gradient" style={{ fontWeight: "700" }}>
          {prefixWord}
        </span>{" "}
        <span style={{ fontWeight: "100" }}>{currentWord}</span>
        <span
          className="ekorn-gradient-vertical"
          style={{ fontWeight: "bold" }}
        >
          |
        </span>
      </h1>
    );
  }

  prefixWord = currentWord.split(" ")[0];
  const query = currentWord.substring(prefixWord.length, currentWord.length);
  return (
    <h1 className="landing-page-animation-text">
      <span className="ekorn-gradient" style={{ fontWeight: "700" }}>
        {prefixWord}
      </span>
      {query.length > 0 ? " " : ""}
      <span style={{ fontWeight: "100" }}>{query}</span>
      <span className="ekorn-gradient-vertical" style={{ fontWeight: "bold" }}>
        |
      </span>
    </h1>
  );
}
