// Function to darken a color by a certain percentage
export function darkenColor(hexColor, percent) {
  // Remove the '#' character if it's present
  hexColor = hexColor.replace(/^#/, "");

  // Parse the hex color to RGB
  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);

  // Calculate the darkened RGB values
  const darkenedR = Math.round(r * (1 - percent / 100));
  const darkenedG = Math.round(g * (1 - percent / 100));
  const darkenedB = Math.round(b * (1 - percent / 100));

  // Convert the darkened RGB values back to hexadecimal
  const darkenedHex = `#${darkenedR.toString(16).padStart(2, "0")}${darkenedG
    .toString(16)
    .padStart(2, "0")}${darkenedB.toString(16).padStart(2, "0")}`;

  return darkenedHex;
}
