import "./PricingPage.css";
import BasePage from "../BasePage";
import PricingBoxV2 from "./PricingBoxV2";
import { JEY_CALENDLY_INVITE } from "../../utils/ProductUtils";
import { getAnalytics, logEvent } from "firebase/analytics";
import FAQsList from "./FAQsList";
import BookAMeeting from "./BookAMeeting";
import { useEffect, useRef } from "react";
import { setUpEntryAnimation } from "../../utils/AnimationUtils";

const PricingPage = () => {
  return (
    <BasePage
      pageTitle="Pricing"
      pageDescription="Our pricing model allows you to enjoy different features while ensuring the best experience for your employees. See our FAQs or book a demo."
    >
      <PricingPageContent />
    </BasePage>
  );
};

const PricingPageContent = () => {
  const sectionRef = useRef(null);
  const analytics = getAnalytics();

  const handleBookDemoCTAClick = () => {
    logEvent(analytics, "book_a_demo_button_clicked");
    window.open(JEY_CALENDLY_INVITE, "_blank");
  };

  useEffect(() => {
    setUpEntryAnimation(sectionRef);
  }, [sectionRef]);

  return (
    <div className="pricing-page-container">
      <div
        className="pricing-page-title-container animated-section"
        ref={sectionRef}
      >
        <h1 className="pricing-page-title">
          Unlimited shortcuts, search with AI, connect your company
        </h1>
        <h2 className="pricing-page-subtitle">
          Pay by the month or the year, and cancel at any time.
        </h2>
      </div>
      <PricingBoxV2 onCTAClicked={handleBookDemoCTAClick} />
      <FAQsList />
      <BookAMeeting />
    </div>
  );
};

export default PricingPage;
