import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import LandingPage from "./pages/landing_page/LandingPage";
import InstallPage from "./pages/InstallPage";
import LoginPage from "./pages/LoginPage";
import SearchPage from "./pages/SearchPage";
import CommandsPage from "./pages/CommandsPage";
import PrivacyPage from "./pages/PrivacyPage";
import PricingPage from "./pages/pricing_page/PricingPage";
import AboutPage from "./pages/about/AboutPage";
import FallbackMetaTags from "./FallbackMetaTags";
import UTMTracker from "./UTMTracker";
import StoryPage from "./pages/story/StoryPage";

function App() {
  return (
    <Router>
      <div className="App">
        <UTMTracker />
        <FallbackMetaTags />
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/install" element={<InstallPage />} />
          <Route exact path="/pricing" element={<PricingPage />} />
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/search" element={<SearchPage />} />
          <Route exact path="/ekorn" element={<CommandsPage />} />
          <Route exact path="/privacy" element={<PrivacyPage />} />
          <Route exact path="/about" element={<AboutPage />} />
          <Route exact path="/story" element={<StoryPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
